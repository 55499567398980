import {Component} from "react";
import Api from "../../../Common/Lib/Api";

export default class AddPracticeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practice: {...props.practice},
            tags: tagsToString(props.practice.tags)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.practice.id !== state.practice.id) {
            const practice = {...props.practice}
            const tags = tagsToString(props.practice.tags)
            return {
                practice,
                tags,
            };
        } else {
            return state
        }
    }

    render() {
        const isEdit = !!this.state.practice.id
        return (
            <div className="modal fade" id="practice-modal" aria-hidden="true" tabIndex='-1'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={e => this.handleSubmit(e)}>
                        <div className="modal-header">
                            <h4 className="modal-title">{isEdit ? 'Edit' : 'Add'} Match</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type={"hidden"} name={'id'} value={this.state.practice.id}/>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <label>Date</label>
                                    <input name={'date'} disabled={this.state.practice.source === 'Garmin' ? 'disabled' : ''} type="date" onChange={(e) => this.onChange(e)} value={this.state.practice.date} className="form-control"/>
                                </div>
                                <div className={'col-6'}>
                                    <label>Duration (in minutes)</label>
                                    <input name={'duration'} value={Math.floor(this.state.practice.duration/60)} onChange={(e) => this.onChange(e)} disabled={this.state.practice.source === 'Garmin' ? 'disabled' : ''} type="number" min={'0'} max={'600'} className="form-control"/>
                                </div>
                                <div className={'col-6'}>
                                    <label>Tags</label>
                                    <input name={'tags'} placeholder={'#serves #drills'} value={this.state.tags} onChange={(e) => this.setTags(e)} type="text" className="form-control"/>
                                </div>
                                    <div className={'col-6'}>
                                        <label>Note</label>
                                        <textarea className={'form-control'} value={this.state.practice.comment} name={'comment'} onChange={(e) => this.onChange(e)} maxLength={200}/>
                                    </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Save changes</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>)
    }

    async handleSubmit (e) {
        e.preventDefault()
        const {practice} = this.state
        practice.tags = tagsToArray(this.state.tags || '')
        if(practice.id) {
            await Api.put(`practice/${practice.id}`, practice)
        } else {
            await Api.post('practice', practice)
        }
        window.location.reload(false);
    }

    onChange(e) {
        const state = {...this.state}
        let value = e.target.value

        if(e.target.name === 'duration') {
            value = value*60
            if(value > 600*60) {
                value = 600*60
            }
            if(value < 0) {
                value = 0
            }
        }

        state.practice[e.target.name] = value;
        this.setState(state)
    }

    setTags(e) {
        const state = {...this.state}
        state.tags = e.target.value
        this.setState(state)
    }
}

function tagsToString(tags) {
    return tags.filter(tag => !!tag).map(tag => !tag.startsWith('#') ? `#${tag}` : tag).join(' ')
}

function tagsToArray(tags) {
    return tags.split('#').filter(tag => !!tag).map(tag => tag.trim())
}
