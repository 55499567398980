import {Component} from "react";
import Api from "../../Common/Lib/Api";
import moment from "moment/moment";
import Loading from "../../Common/Loading";
import PracticesTable from "./Components/PracticesTable";
import WarningBar from "../../Common/WarningBar";
import {getConfiguration} from "../../Common/Storage";

class Practices extends Component {

    state = {
        isLoading: false,
        practices: [],
        displayPractices: [],
        // filters: {
        //     player: 'all',
        //     matchType: 'all',
        //     surface: 'all',
        //     dateFrom: moment('2023-01-01').format('YYYY-MM-DD'),
        //     dateTo: moment().endOf('year').format('YYYY-MM-DD'),
        //     tags: {
        //         and: [],
        //         or: [],
        //         exluded: []
        //     },
        //     showUnfinished: false,
        // }
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        const allPractices = (await Api.get('practice/list'))
        // if(false) {
        //     allMatches.map(match => {
        //         match.opponent = fakeName()
        //         match.tags = randomTags()
        //         return match
        //     })
        // }
        allPractices.sort((practice1, practice2) => {
            return moment(practice1.date).isBefore(moment(practice2.date)) ? 1 : -1
        })

        this.setState({practices: allPractices, displayPractices: allPractices, isLoading: false})
        // this.applyfilters(this.state.filters)
    }

    renderWarningBar() {
        const configuration = getConfiguration()
        const isConnected = configuration.devices.filter(device => device.appKey === 'tennisPracticeTracker' && device.isConnected === true).length > 0
        return !isConnected ? (
            <WarningBar type="info" header="Info" text={`It seems to the <a target="_blank" href="https://apps.garmin.com/apps/5c3c98fe-cc38-432d-9227-94737e823daf" >AMR Tennis Practice Tracker</a> app is not connected to this dashboard.<br/>To be able to automatically sync your practices from your watch app,
                please go to the <a href="/profile">profile</a> section and connect your app to the cloud by following the insturctions: <a target="_blank" href="https://allmyrackets.com/cloud-sync">https://allmyrackets.com/cloud-sync</a>`}/>
        ) : undefined
    }

    render() {
        // const onFilterChange = (filters) => {
        //     // this.applyfilters(filters)
        // }
        return this.state.isLoading ? <Loading/> : (<div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1 className="m-0">Practices</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    {this.renderWarningBar()}
                    <div className="row">
                        <div className="col-lg-12">
                            {/*<FiltersSection matches={this.state.matches} onFilterChange={onFilterChange} defaults={this.state.filters} />*/}
                        </div>
                    </div>
                    <div className="row">
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <PracticesTable practices={this.state.displayPractices} title={'Practices'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Practices