import {Component} from "react";
import StatsRow from "./StatsRow";
import StatsRowHeader from "./StatsRowHeader";

export default class ScoresErrorsTab extends Component {
   render() {
       const p1Stats = this.props.playerStats.p1Stats || {}
       const p2Stats = this.props.playerStats.p2Stats || {}

       return (
           <table className="table table-borderless">
               <tbody>
               <StatsRowHeader
                   header={'Points Scored'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p1Stats.points,
                       total:  p1Stats.points +  p2Stats.points
                   }}
                   p2={{
                       value:  p2Stats.points,
                       total:  p1Stats.points +  p2Stats.points
                   }}
                   label={'Total'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p1Stats.winners,
                       total:  p1Stats.points
                   }}
                   p2={{
                       value:  p2Stats.winners,
                       total:  p2Stats.points
                   }}
                   label={'Winners'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.8.0'}
                   p1={{
                       value:  p1Stats.return.returnWinners,
                       total:  p1Stats.points
                   }}
                   p2={{
                       value:  p2Stats.return.returnWinners,
                       total:  p2Stats.points
                   }}
                   label={'Return winners'}
                   tooltip={'Point scored as a winner directly from return.'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p2Stats.forcedErrors,
                       total:  p1Stats.points
                   }}
                   p2={{
                       value:  p1Stats.forcedErrors,
                       total:  p2Stats.points
                   }}
                   label={'Forced Errors'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p2Stats.unforcedErrors,
                       total:  p1Stats.points
                   }}
                   p2={{
                       value:  p1Stats.unforcedErrors,
                       total:  p2Stats.points
                   }}
                   label={'Unforced Errors'} />


               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p2Stats.serve.doubleFaults,
                       total:  p1Stats.points
                   }}
                   p2={{
                       value:  p1Stats.serve.doubleFaults,
                       total:  p2Stats.points
                   }}
                   label={'Double Faults'} />

               <StatsRowHeader
                   header={'Errors'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p1Stats.forcedErrors,
                       total:  p1Stats.forcedErrors +  p2Stats.forcedErrors
                   }}
                   p2={{
                       value:  p2Stats.forcedErrors,
                       total:  p1Stats.forcedErrors +  p2Stats.forcedErrors
                   }}
                   label={'Forced Errors'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p1Stats.unforcedErrors,
                       total:  p1Stats.unforcedErrors +  p2Stats.unforcedErrors
                   }}
                   p2={{
                       value:  p2Stats.unforcedErrors,
                       total:  p1Stats.unforcedErrors +  p2Stats.unforcedErrors
                   }}
                   label={'Unforced Errors'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   p1={{
                       value:  p1Stats.serve.doubleFaults,
                       total:  p1Stats.serve.doubleFaults +  p2Stats.serve.doubleFaults,
                   }}
                   p2={{
                       value:  p2Stats.serve.doubleFaults,
                       total:  p1Stats.serve.doubleFaults +  p2Stats.serve.doubleFaults,
                   }}
                   label={'Double Faults'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.forcedErrors,
                       total:  p1Stats.forcedErrors +  p1Stats.unforcedErrors
                   }}
                   p2={{
                       value:  p2Stats.forcedErrors,
                       total:  p2Stats.forcedErrors +  p2Stats.unforcedErrors
                   }}
                   label={'Forced Errors / Errors'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.unforcedErrors,
                       total:  p1Stats.forcedErrors +  p1Stats.unforcedErrors
                   }}
                   p2={{
                       value:  p2Stats.unforcedErrors,
                       total:  p2Stats.forcedErrors +  p2Stats.unforcedErrors
                   }}
                   label={'Unforced Errors / Errors'} />

               </tbody>
           </table>
       )
   }
}