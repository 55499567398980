import {Component} from "react";
// import moment from "moment";
// import MatchesTablePaging from "./MatchesTablePaging";

class PerformanceTable extends Component {
    render() {
        const stats = this.props.matches.reduce((stats, match) => {
            if(match.matchResult === 1) {
                stats.matchesWon++
            } else if (match.matchResult === -1) {
                stats.matchesLost++
            }
            stats.gamesLost+=match.details.gamesLost
            stats.gamesWon+=match.details.gamesWon
            stats.setsWon+=match.details.setsWon
            stats.setsLost+=match.details.setsLost
            stats.tieBreaksWon+=match.details.tieBreaksWon
            stats.tieBreaksLost+=match.details.tieBreaksLost
            stats.matchesTotal++;
            return stats
        }, {
            matchesTotal : 0,
            gamesWon: 0,
            gamesLost: 0,
            setsWon: 0,
            setsLost: 0,
            tieBreaksWon: 0,
            tieBreaksLost: 0,
            matchesWon: 0,
            matchesLost: 0
        })
        const performance = this.props.matches.slice(0,10).map(match => {
            const className = `badge ${match.matchResult === 1 ? 'badge-success' : match.matchResult === 0 ? 'badge-primary' : 'badge-danger'}`
            const result = match.matchResult === 1 ? ' W ' : match.matchResult === 0 ? ' U ' : ' L '
            return (<span key={match._id} style={{margin: '0 1px'}} className={className}>{result}</span>)
        })
        return (
            <div className="card">
                <div className={'card-header'}>
                    <h3 style={{width: '290px'}} className="card-title">{this.props.title}</h3><span>{performance}</span>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body table-responsive p-0">
            <table className="table table-borderless">
            <tbody>
            <tr>
                <td style={{width: '200px'}}>Matches Won</td>
                <td style={{width: '90px'}}>{stats.matchesWon} / {stats.matchesTotal}</td>
                <td>
                    {this.getProgressBar(this.calcPercent(stats.matchesWon, stats.matchesTotal))}
                </td>
                <td>
                    {this.calcPercent(stats.matchesWon, stats.matchesTotal)}%
                </td>
            </tr>
            <tr>
                <td>Sets Won</td>
                <td>{stats.setsWon} / {stats.setsLost + stats.setsWon}</td>
                <td>
                    {this.getProgressBar(this.calcPercent(stats.setsWon, stats.setsWon + stats.setsLost))}
                </td>
                <td>
                    {this.calcPercent(stats.setsWon, stats.setsWon + stats.setsLost)}%
                </td>
            </tr>
            <tr>
                <td>Games Won</td>
                <td>{stats.gamesWon} / {stats.gamesLost + stats.gamesWon}</td>
                <td>
                    {this.getProgressBar(this.calcPercent(stats.gamesWon, stats.gamesWon + stats.gamesLost))}
                </td>
                <td>
                    {this.calcPercent(stats.gamesWon, stats.gamesWon + stats.gamesLost)}%
                </td>
            </tr>
            <tr>
                <td>Tie breaks Won</td>
                <td>{stats.tieBreaksWon} / {stats.tieBreaksLost + stats.tieBreaksWon}</td>
                <td>
                    {this.getProgressBar(this.calcPercent(stats.tieBreaksWon, stats.tieBreaksWon + stats.tieBreaksLost))}
                </td>
                <td>
                    {this.calcPercent(stats.tieBreaksWon, stats.tieBreaksWon + stats.tieBreaksLost)}%
                </td>
            </tr>
            </tbody>
        </table>
                </div>
            </div>)}

    calcPercent(number, total) {
        return Math.round(number/total*100) || 0
    }

    getProgressBar(percent) {
        return (<div className="progress">
            <div className={`progress-bar ${this.getBarColor(percent)}`} style={{width: `${percent}%`}}></div>
        </div>)
    }

    getBarColor(percent) {
        if(percent <= 25) {
            return 'bg-red'
        }
        if(percent <= 55) {
            return 'bg-yellow'
        }
        return 'bg-green'
    }
        }

export default PerformanceTable