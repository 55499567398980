import {Component, Fragment} from "react";
export default class GuestLayout extends Component{
    render() {
        return (
        <Fragment>
            <div className={'card'}>
                {this.props.content}
                <footer className="card-footer">
                    <div className="float-right d-none d-sm-block">
                        Powered by: <b><a href={'https://allmyrackets.com'} rel="noreferrer" target={'_blank'}>allmyrackets.com</a></b>
                    </div>
                </footer>
            </div>
        </Fragment>
    )}
}