import {Component} from "react";
import moment from "moment";
import MatchesTablePaging from "./MatchesTablePaging";
import AddMatchForm from "./AddMatchForm";
import RemoveMatchDialog from "./RemoveMatchDialog";
import {Link} from "react-router-dom";
import {getConfiguration} from "../../../Common/Storage";

class MatchesTable extends Component {
    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
    }

    state = {
        page: 1,
        perPage: 10,
        currentMatch: {
            id: '',
            date: moment().format('YYYY-MM-DD'),
            surface: 'Clay',
            opponent: '',
            doubleOpponent: '',
            doublePartner: '',
            isDouble: false,
            result: '',
            winner: 0,
            tags: [],
            origin: 'Web',
            comment: '',
        }
    }

    hasAccess() {
        const configuration = getConfiguration()
        return configuration.licenses.filter(license => license.status === 'active' && license.product === 'scoreTracker').length > 0
    }

    render() {
        const players = this.props.matches.map(match => match.opponent).filter((opponent, index, array) => array.indexOf(opponent) === index)
        const offset = (this.state.page * this.state.perPage) - this.state.perPage
        const limit = offset + this.state.perPage
        const getColor = () => {
            const colors = ['blue']
            return colors[Math.floor(Math.random() * (colors.length)) + 0]
        }
        return (<div className="card">
            <div className={'card-header'}>
                <h3 className="card-title">{this.props.title} {`${offset+1} - ${limit < this.props.matches.length ? limit : this.props.matches.length } of ${this.props.matches.length}`}</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                </div>
            </div>

            <div className="card-body table-responsive p-0">
                {/*<h5 className="card-title">Matches</h5>*/}
                <table className="table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Opponent</th>
                        <th>Result</th>
                        <th>Rules</th>
                        <th>Surface</th>
                        <th>Tags</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.props.matches.map(match => {
                        const badgeClass = `badge badge-${match.matchResult === 1 ? 'success' : match.matchResult === 0 ? 'primary' : 'danger'}`
                        return (<tr key={match._id.toString()}>
                                <td>{moment(match.matchDate).format('DD.MM.YYYY')}
                                </td>
                                <td>{match.opponent} {match.isDouble ? '/ '+match.doubleOpponent : undefined}</td>
                                <td><span className={badgeClass}>{match.rawResult}</span></td>
                                <td>{match.rules}</td>
                                <td>{match.surface}</td>
                                <td style={{maxWidth: '80px'}}>{match.tags.sort().map(tag => (
                                    <span key={`${match._id.toString()}${tag}`} style={{margin: '0 2px'}}
                                          className={`badge bg-${getColor()}`}>{tag}</span>))}
                                </td>
                                <td style={{maxWidth: '150px'}}>{match.comment}</td>
                                <td>
                                    <Link style={{margin: '0 5px 0 0'}} className={'btn btn-xs btn-primary'}
                                          to={`/match/${match._id.toString()}`}>
                                        <i className={`fas ${match.origin === 'Garmin' ? 'fa-chart-bar' : 'fa-location-arrow'}`}></i>
                                    </Link>
                                    {this.hasAccess() ? (<span style={{margin: '0 5px 0 0'}} className="btn btn-xs btn-primary"
                                       onClick={(e) => this.setMatch(match)}>
                                        <i className="fas fa-pen" data-toggle="modal" data-target="#match-modal"></i>
                                    </span>) : undefined}
                                    {this.hasAccess() ? (<span style={{margin: '0 5px 0 0'}} className="btn btn-xs btn-danger"
                                       onClick={(e) => this.setMatch(match)}>
                                        <i className="fas fa-trash" data-toggle={'modal'}
                                           data-target={'#remove-match-modal'}></i> </span>) : undefined}
                                </td>
                            </tr>
                        )
                    }).slice(offset, limit)}
                    </tbody>
                </table>
            </div>
            <div className="card-footer clearfix">
                {this.hasAccess() ? (<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#match-modal"
                        onClick={(e) => this.clearMatch()}>
                    Add Match
                </button>) : undefined}
                <AddMatchForm match={this.state.currentMatch} players={players}/>
                <RemoveMatchDialog matchId={this.state.currentMatch.id}/>
                <MatchesTablePaging page={this.state.page} items={this.props.matches.length}
                                    perPage={this.state.perPage} onPageChange={this.onPageChange}/>
            </div>
        </div>)
    }

        onPageChange(event)
        {
            event.preventDefault();
            const val = event.target.getAttribute('data-page');
            this.changePage(val);
        }

        changePage(page)
        {
            const vals = {...this.state}
            let newPage;
            if (page === 'last') {
                newPage = Math.ceil(this.props.matches.length / this.state.perPage)
            } else if (page === 'first') {
                newPage = 1
            } else {
                newPage = parseInt(page)
            }
            vals.page = parseInt(newPage)
            this.setState(vals)
        }

        setMatch(match)
        {
            const state = {...this.state}
            state.currentMatch = {
                id: match._id ? match._id.toString() : '',
                date: moment(match.matchDate).format('YYYY-MM-DD'),
                surface: match.surface,
                opponent: match.opponent,
                doubleOpponent: match.doubleOpponent,
                doublePartner: match.doublePartner,
                isDouble: !!match.doublePartner,
                result: match.rawResult,
                winner: match.winnerDetection === undefined ? 'auto' : match.winnerDetection,
                tags: match.tags || [],
                origin: match.origin,
                comment: match.comment || '',
            }
            this.setState(state)
        }

        clearMatch()
        {
            this.setMatch({
                surface: 'Clay',
                date: moment().format('YYYY-MM-DD'),
                opponent: '',
                rawResult: '',
                tags: [],
                comment: ''
            })
        }

    }

    export default MatchesTable
