import {Component} from "react";
import {Doughnut} from "react-chartjs-2";

export default class PointsDistribution extends Component {
    render() {
        const p1Stats = this.props.playerStats.p1Stats || {}
        const p2Stats = this.props.playerStats.p2Stats || {}
       return (
           <div className={'card-body'}>
               <div className={'row'} style={{padding: '10px 0'}}>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                       {this.renderInGameDonut({
                           data: [p2Stats.forcedErrors, p2Stats.unforcedErrors, p1Stats.winners, p1Stats.serve.aces, p1Stats.serve.dirtyAces, p1Stats.return.returnWinners, p2Stats.serve.doubleFaults],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Winner',
                               'Ace',
                               'Dirty Ace',
                               'Return Winner',
                               'Double Faults'
                           ],
                           position: 'right'
                       })}
                   </div>
                   <div className={'col-2'} style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                       <span>All Scores</span>
                   </div>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-start'}}>
                       {this.renderInGameDonut({
                           data: [p1Stats.forcedErrors, p1Stats.unforcedErrors, p2Stats.winners, p2Stats.serve.aces, p2Stats.serve.dirtyAces, p2Stats.return.returnWinners, p1Stats.serve.doubleFaults],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Winner',
                               'Ace',
                               'Dirty Ace',
                               'Return Winner',
                               'Double Faults'
                           ],
                           position: 'left'
                       })}
                   </div>
               </div>
               <div className={'row'} style={{padding: '10px 0'}}>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                       {this.renderInGameDonut({
                           data: [p2Stats.forcedErrors, p2Stats.unforcedErrors, p1Stats.winners, ],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Winner'
                           ],
                           position: 'right'
                       })}
                   </div>
                   <div className={'col-2'} style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                       <span>In Game Scores</span>
                   </div>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-start'}}>
                       {this.renderInGameDonut({
                           data: [p1Stats.forcedErrors, p1Stats.unforcedErrors, p2Stats.winners],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Winner'
                           ],
                           position: 'left'
                       })}
                   </div>
               </div>

               <div className={'row'} style={{padding: '10px 0'}}>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                       {this.renderInGameDonut({
                           data: [p1Stats.forcedErrors, p1Stats.unforcedErrors, p1Stats.serve.doubleFaults],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Double Faults',
                           ],
                           position: 'right'
                       })}
                   </div>
                   <div className={'col-2'} style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                       <span>Errors</span>
                   </div>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-start'}}>
                       {this.renderInGameDonut({
                           data: [p2Stats.forcedErrors, p2Stats.unforcedErrors, p2Stats.serve.doubleFaults],
                           labels: [
                               'Forced Error',
                               'Unforced Error',
                               'Double Faults',
                           ],
                           position: 'left'
                       })}
                   </div>
               </div>

               <div className={'row'} style={{padding: '10px 0'}}>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                       {this.renderInGameDonut({
                           data: [
                               p2Stats.forcedErrors + p1Stats.winners + p1Stats.return.returnWinners,
                               p2Stats.unforcedErrors + p1Stats.serve.doubleFaults,
                               p1Stats.serve.dirtyAces + p1Stats.serve.aces
                           ],
                           labels: [
                               'Active',
                               'Passive',
                               'Serve'
                           ],
                           position: 'right'
                       })}
                   </div>
                   <div className={'col-2'} style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                       <span>Passive / Active</span>
                   </div>
                   <div className={'col-5'} style={{display: 'flex', justifyContent: 'flex-start'}}>
                       {this.renderInGameDonut({
                           data: [
                               p1Stats.forcedErrors + p2Stats.winners + p2Stats.serve.aces + p2Stats.serve.dirtyAces + p2Stats.return.returnWinners,
                               p1Stats.unforcedErrors + p2Stats.serve.doubleFaults,
                               p2Stats.serve.dirtyAces + p2Stats.serve.aces
                           ],
                           labels: [
                               'Active',
                               'Passive',
                               'Serve'
                           ],
                           position: 'left'
                       })}
                   </div>
               </div>
           </div>
       )
    }

    renderInGameDonut({labels, data, position= 'left'} = {}) {
        const chartData = {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor : ['#f39c12', '#00a65a', '#f56954','#00c0ef', '#3c8dbc', '#d2d6de', '#3427c2'],
                }
            ]
        }
        const options = {
            plugins: {
                title: {
                    display: false,
                    text: 'Serve 1',
                },
                legend: {
                    display: window.innerWidth > 800,
                    position
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const total = context.dataset.data.reduce((sum, curr) => sum + curr, 0)
                            const percent = Math.round((context.raw/total)*100)
                            return `${context.raw} (${percent}%)`
                        }
                    }
                }
            }
        }
        return (
                <Doughnut style={{maxHeight: '200px', maxWidth: '400px'}} options={options} data={chartData} />
        )
    }
}