import {Component} from "react";
import {NavLink} from "react-router-dom";

const routes = [
    {path: '/dashboard', name: 'Dashboard', icon: 'fas fa-tachometer-alt'},
    {path: '/statistics', name: 'Statistics', icon: 'fas fa-chart-pie'},
    {path: '/matches', name: 'Matches', icon: 'fas fa-table'},
    {path: '/practices', name: 'Practices', icon: 'fas fa-dumbbell'},
    {path: '/profile', name: 'Profile', icon: 'fas fa-user'},
]

class Sidebar extends Component {
    render() {
        return (<aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        {/*<img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User"/>*/}
                    </div>
                    <div className="info">
                        {/*<a href="#" className="d-block">{this.state.user?.name || ''}</a>*/}
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        {routes.map(route => (
                            <li key={`li${route.path}`} className="nav-item">
                                <NavLink key={route.path} to={route.path}
                                         className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                                    <i className={`nav-icon ${route.icon}`}></i>
                                    <p>{route.name}</p>
                                </NavLink>
                            </li>))}

                    </ul>
                </nav>
            </div>
        </aside>)
    }
}

export default Sidebar