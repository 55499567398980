import {Component} from "react";
import Api from "../../../Common/Lib/Api";

export default class RemoveMatchDialog extends Component {
    render() {
        return (
            <div className="modal fade" id="remove-match-modal" aria-hidden="true" tabIndex='-1'>
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Delete Match</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" onClick={(e) => this.confirm(e)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>)
    }

    async confirm (e) {
        e.preventDefault()
        const {matchId} = this.props
            await Api.delete(`match/${matchId}`)
        window.location.reload(false);
    }
}
