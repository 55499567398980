import {Component} from "react";

class PlayerImageSvg extends Component {
    render()
    {
        const stringToColour = function(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }
        const hash = this.props.hash || '';

        // const bandColor = '#E6EBF0';
        // const tshirtColor = '#E6EBF0';
        // const cuffColor = '#5F69BE'

        const bandColor = stringToColour(hash);
        const tshirtColor = stringToColour(hash);
        const cuffColor = stringToColour(hash+"a")
        return (<svg
            className={this.props.className}
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 500.673 500.673'}
        >
            <g>
                <path fill="#FAD7A5" d="M458.452,403.073l-0.115,0.052v-3.609c0-35.201-27.43-72.779-60.955-83.506l-43.968-14.07
		l0.923-1.269l-18.633-18.633c-3.009-3.009-7.846-3.136-11.009-0.29l-0.524,0.471c-1.613-7.881-1.914-15.901-1.921-21.846
		c12.45-13.545,21.979-30.307,28.616-48.23c14.99,5.722,27.47-25.439,27.47-39.473c0-10.721-6.233-16.937-16.163-16.7
		c0.105-2.452,0.163-4.888,0.163-7.3c0-64.065-50.144-88-112-88s-112,23.935-112,88c0,2.438,0.06,4.901,0.167,7.379
		c-9.886-0.919-16.167,5.374-16.167,16.621c0,14.682,12.515,48.108,27.086,38.431c6.647,18.303,16.297,35.444,28.977,49.246
		c-0.03,5.923-0.37,13.912-2.007,21.773l-0.414-0.373c-3.163-2.846-8-2.719-11.009,0.29l-18.633,18.633l0.923,1.269l-43.968,14.07
		c-33.525,10.727-60.955,48.305-60.955,83.506v3.609l-0.115-0.052c-3.692-1.678-7.885,1.021-7.885,5.077v10.676
		c0,4.281,2.502,8.166,6.399,9.938l9.601,4.364v43.545c0,13.255,10.745,24,24,24h352c13.255,0,24-10.745,24-24v-43.545l9.601-4.364
		c3.897-1.771,6.399-5.657,6.399-9.938V408.15C466.336,404.094,462.144,401.395,458.452,403.073z"/>
                <path fill="#F0C891" d="M306.336,340.673l31.671-34.197c-17.891-11.519-15.671-45.803-15.671-53.803h-144
		C188.668,282.376,257.003,333.339,306.336,340.673z"/>
                <path fill={tshirtColor} d="M122.336,500.673v-48l-80-40v-13.155c0-35.201,27.43-72.779,60.955-83.506l50.05-16.016
		c26.928,20.59,65.409,48.677,96.995,48.677s70.067-28.087,96.995-48.677l50.05,16.016c33.525,10.727,60.955,48.305,60.955,83.506
		v13.155l-80,48v40H122.336z"/>
                <path fill="#D2D7DC" d="M258.336,460.673h-16c-8.837,0-16-7.163-16-16v-96h48v96
		C274.336,453.509,267.173,460.673,258.336,460.673z"/>
                <path fill={cuffColor} d="M250.336,348.673l-33.38,33.38c-3.476,3.476-9.235,3.024-12.127-0.951l-58.493-80.428l18.633-18.633
		c3.009-3.009,7.846-3.136,11.009-0.29L250.336,348.673z"/>
                <path fill={cuffColor} d="M250.336,348.673l33.38,33.38c3.476,3.476,9.235,3.024,12.127-0.951l58.493-80.428l-18.633-18.633
		c-3.009-3.009-7.846-3.136-11.009-0.29L250.336,348.673z"/>
                <circle fill={cuffColor} cx="250.336" cy="428.673" r="8"/>
                <circle fill={cuffColor} cx="250.336" cy="388.673" r="8"/>
                <path fill="#FFE1B4" d="M362.173,155.973c0.105-2.452,0.163-4.888,0.163-7.3c0-64.065-50.144-88-112-88s-112,23.935-112,88
		c0,2.438,0.06,4.901,0.167,7.379c-9.886-0.919-16.167,5.374-16.167,16.621c0,14.682,12.515,48.108,27.086,38.431
		c16.033,44.151,49.526,81.569,100.914,81.569c50.983,0,84.35-36.831,100.53-80.527c14.99,5.722,27.47-25.439,27.47-39.473
		C378.336,161.951,372.103,155.736,362.173,155.973z"/>
                <path fill="#73554B" d="M298.336,4.673l8,16c0,0-45-32.5-120-16c-59.455,13.08-80,56-80,56l32,64v38.5l16,8
		c0,0,12.506-57.547,15.428-68.5h157.645c2.921,10.953,18.928,68.5,18.928,68.5l16-8v-38.5v-16v-32
		C362.336,69.173,360.836,18.423,298.336,4.673z"/>
                <path fill={cuffColor} d="M40.735,428.763l81.601,37.092V439.49L42.22,403.073c-3.692-1.678-7.884,1.021-7.884,5.077v10.676
		C34.336,423.106,36.838,426.992,40.735,428.763z"/>
                <path fill={cuffColor} d="M459.938,428.763l-81.601,37.092V439.49l80.116-36.417c3.692-1.678,7.884,1.021,7.884,5.077v10.676
		C466.336,423.106,463.834,426.992,459.938,428.763z"/>
                <path fill={bandColor} d="M130.336,78.969v55.356c0,2.817,3.024,4.507,5.466,3.102c70.886-40.796,158.182-40.796,229.069,0
		c2.441,1.405,5.466-0.285,5.466-3.102V78.969c0-5.233-2.733-10.171-7.292-12.74c-69.945-39.409-155.47-39.409-225.415,0
		C133.07,68.798,130.336,73.736,130.336,78.969z"/>
                <path fill="#73554B" d="M158.088,53.011c-5.332,19.58-3.752,39.662-3.752,39.662c14-26,69.333-56,96-56
		C250.336,36.673,194.086,32.923,158.088,53.011z"/>
            </g>
        </svg>)

    }
}
export default  PlayerImageSvg