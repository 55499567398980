import {Component} from "react";

class BagelSvg extends Component {
    render() {
        return (<svg
            className={this.props.className}
            style={this.props.style}
            key={this.props.uniqKey}
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 512 512'}
        >
            <polygon style={{fill: '#F4B2B0'}} points="154.503,390.752 122.717,269.008 188.572,305.952 256,200.449 323.428,305.952
	389.283,269.008 357.497,390.752 "/>
            <path style={{fill: '#B3404A'}} d="M357.497,402.885H154.503c-5.521,0-10.345-3.726-11.74-9.068l-31.786-121.744
	c-1.229-4.708,0.473-9.696,4.324-12.669c3.853-2.974,9.109-3.357,13.351-0.978l55.864,31.34l61.26-95.85
	c2.229-3.488,6.083-5.599,10.223-5.599s7.994,2.111,10.223,5.599l61.26,95.85l55.864-31.34c4.243-2.381,9.499-1.996,13.351,0.978
	c3.852,2.974,5.553,7.962,4.324,12.669l-31.786,121.744C367.842,399.158,363.017,402.885,357.497,402.885z M163.875,378.619h184.249
	l22.212-85.07l-40.972,22.985c-5.605,3.146-12.696,1.371-16.159-4.048L256,222.98l-57.205,89.507
	c-3.463,5.419-10.55,7.195-16.159,4.048l-40.972-22.985L163.875,378.619z"/>
            <g>
                <circle style={{fill: '#F4B2B0'}} cx="255.995" cy="110.326" r="37.612"/>
                <circle style={{fill: '#F4B2B0'}} cx="462.257" cy="189.603" r="37.612"/>
                <circle style={{fill: '#F4B2B0'}} cx="49.745" cy="189.603" r="37.612"/>
            </g>
            <g>
                <path style={{fill: '#B3404A'}} d="M462.255,139.853c-27.429,0-49.745,22.316-49.745,49.745c0,1.121,0.051,2.229,0.124,3.332
		l-67.098,34.965l-53.353-83.48c8.398-8.911,13.562-20.903,13.562-34.085c0-27.429-22.316-49.745-49.745-49.745
		s-49.745,22.316-49.745,49.745c0,13.183,5.164,25.175,13.562,34.085l-59.334,92.84c-3.61,5.647-1.957,13.149,3.69,16.758
		c5.646,3.607,13.149,1.956,16.757-3.69l59.314-92.806c0.167,0.056,0.339,0.103,0.507,0.158c0.323,0.104,0.647,0.205,0.972,0.302
		c0.347,0.103,0.695,0.201,1.045,0.297c0.415,0.115,0.831,0.228,1.251,0.332c0.109,0.027,0.22,0.051,0.33,0.076
		c3.741,0.901,7.64,1.392,11.654,1.392c4.014,0,7.912-0.49,11.653-1.392c0.11-0.027,0.222-0.05,0.332-0.076
		c0.419-0.104,0.835-0.217,1.25-0.332c0.349-0.096,0.698-0.194,1.045-0.297c0.325-0.097,0.649-0.198,0.972-0.302
		c0.169-0.055,0.34-0.102,0.507-0.158l59.314,92.806c2.3,3.6,6.211,5.601,10.232,5.601c1.895,0,3.813-0.444,5.598-1.375
		l73.41-38.255c8.843,13.845,24.333,23.052,41.944,23.052c27.429,0,49.745-22.316,49.745-49.745S489.685,139.853,462.255,139.853z
		 M256,84.85c14.049,0,25.479,11.429,25.479,25.479c0,9.659-5.403,18.079-13.344,22.399c-0.001,0-0.002,0.001-0.004,0.001
		c-0.643,0.349-1.309,0.662-1.984,0.957c-0.2,0.087-0.403,0.167-0.604,0.249c-0.519,0.211-1.048,0.404-1.583,0.581
		c-0.193,0.063-0.383,0.132-0.579,0.192c-0.698,0.211-1.406,0.397-2.127,0.548c-0.164,0.034-0.329,0.057-0.493,0.089
		c-0.593,0.113-1.194,0.205-1.801,0.275c-0.217,0.025-0.434,0.05-0.654,0.07c-0.761,0.069-1.529,0.116-2.308,0.116
		s-1.546-0.049-2.308-0.116c-0.218-0.019-0.436-0.045-0.654-0.07c-0.607-0.07-1.207-0.163-1.801-0.275
		c-0.164-0.032-0.329-0.055-0.493-0.089c-0.721-0.152-1.43-0.337-2.128-0.548c-0.193-0.058-0.383-0.127-0.575-0.19
		c-0.537-0.177-1.068-0.371-1.589-0.582c-0.2-0.081-0.4-0.16-0.598-0.246c-9.012-3.93-15.334-12.916-15.334-23.36
		C230.521,96.279,241.951,84.85,256,84.85z M462.255,215.078c-14.049,0-25.479-11.429-25.479-25.479
		c0-14.05,11.431-25.479,25.479-25.479s25.479,11.429,25.479,25.479C487.734,203.648,476.303,215.078,462.255,215.078z"/>
                <path style={{fill: '#B3404A'}} d="M427.098,316.289c-6.475-1.691-13.112,2.19-14.805,8.674L385.612,427.15H126.39L75.477,232.157
		c14.384-8.73,24.014-24.54,24.014-42.559c0-27.429-22.316-49.745-49.745-49.745S0,162.169,0,189.598s22.316,49.745,49.745,49.745
		c0.842,0,1.679-0.022,2.512-0.063l53.02,203.068c1.394,5.341,6.219,9.068,11.74,9.068h277.968c5.521,0,10.345-3.726,11.74-9.068
		l29.048-111.255C437.465,324.61,433.581,317.983,427.098,316.289z M24.266,189.598c0-14.05,11.431-25.479,25.479-25.479
		s25.479,11.429,25.479,25.479c0,14.05-11.431,25.479-25.479,25.479S24.266,203.648,24.266,189.598z"/>
            </g>
        </svg>)

    }
}

export default BagelSvg