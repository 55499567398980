const GAME_BADGES = {
    PURE_WIN: 'pureWin',
    SURVIVOR: 'survivor',
    SHARP_SHOOTER: 'sharpShooter',
    LOST_BREAK_OPPORTUNITY: 'lostBreakOpportunity',
    GAME: 'game',
    BREAK: 'break',
}

const MATCH_BADGES = {
    BAGEL: 'bagel',
    BREAKER: 'breaker',
    GAMER: 'gamer',
    DOUBLE_BAGEL: 'doubleBagel',
}

export {GAME_BADGES, MATCH_BADGES}