import React, {Component} from "react";

import Api from "../../Common/Lib/Api";
import Loading from "../../Common/Loading";
import ReactApexChart from 'react-apexcharts'
import withSearchParams from "../../Common/Component/withSearchParams";
import {getConfiguration} from "../../Common/Storage";
import WarningBar from "../../Common/WarningBar";

class Statistics extends Component {
    colors = {
        green: '#00E396',
        red: '#FF4560',
        yellow: '#FEB019',
        blue: '#008FFB',
        purple: '#775DD0',
        lightRed: '#D4526E',
        darkBlue: '#D4526E'
    }

    palette = []

    state = {
        isLoading: true,
        doubles: false,
        insights: {}
    }

    constructor() {
        super();
        this.palette = Object.values(this.colors)
    }

    async componentDidMount() {
        await this.setState({
            year: parseInt(this.props.searchParams.get('year') || new Date().getFullYear(), 10),
            doubles: this.props.searchParams.get('doubles') === 'true'
        })
        await this.loadData()
    }

    async loadData() {
        this.setState({isLoading: true})
        const insights = (await Api.get(`insights?year=${this.state.year}&doubles=${this.state.doubles}`))
        this.setState({insights, isLoading: false})
    }

    renderNoLicenseBar() {
        const configuration = getConfiguration()
        const hasAccess = configuration.licenses.filter(license => license.status === 'active' && license.product === 'scoreTracker').length > 0

        return !hasAccess ? (
            <WarningBar type="danger" header="Warning" text={`You are not a <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a> subscriber, so you have limited funcitonality here.<br/>
                To enable the full funcitonality for this page, please subscribe <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a>`}/>
        ) : undefined
    }

    renderHeader() {
        return (<div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <h1 className="m-0">Dashboard</h1>
                    </div>
                </div>
                {this.renderNoLicenseBar()}
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <div className={'btn-group'}>
                            <button className={'btn btn-primary'} onClick={() => this.prevYear()}>{'<'}</button>
                            <div className={'btn btn-primary'}>{this.state.year}</div>
                            <button className={'btn btn-primary'} onClick={() => this.nextYear()}>{'>'}</button>
                        </div>
                        <div className={'btn-group'} style={{margin: '0 10px'}}>
                            <button className={'btn btn-primary'} onClick={() => this.toggleDoubles()}>{'<'}</button>
                            <div className={'btn btn-primary'}>{this.state.doubles ? 'Doubles' : 'Singles'}</div>
                            <button className={'btn btn-primary'} onClick={() => this.toggleDoubles()}>{'>'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    renderContent() {
        return this.state.isLoading ? <Loading/> : (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Serve Accuracy</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.state.insights.serveAccuracy.total ? this.renderRadial([
                                            this.state.insights.serveAccuracy.total,
                                            this.state.insights.serveAccuracy.first,
                                            this.state.insights.serveAccuracy.second,
                                        ], ['Serve Accuracy', 'First Serve In', 'Second Serve In'],
                                        'palette1'
                                    ): this.renderNoData()}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Serve Points Conversion</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderRadial([
                                            this.state.insights.serveScore.total,
                                            this.state.insights.serveScore.first,
                                            this.state.insights.serveScore.second,
                                        ], ['Serve Points', 'First Serve', 'Second Serve'],
                                        'palette1'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Return Conversion</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderRadial([
                                            this.state.insights.returnScore.total,
                                            this.state.insights.returnScore.first,
                                            this.state.insights.returnScore.second,
                                        ], ['Return Points', 'First Serve', 'Second Serve'],
                                        'palette1'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Points conversion</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderRadial([
                                            this.state.insights.serveReturnScore.total,
                                            this.state.insights.serveReturnScore.serve,
                                            this.state.insights.serveReturnScore.return,
                                        ], ['Points Scored', 'Serve', 'Return'],
                                        'palette1'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Scored Points Distribution</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderScoreDistribution(
                                        [
                                            this.state.insights.scoreDistribution.winners,
                                            this.state.insights.scoreDistribution.returnWinners,
                                            this.state.insights.scoreDistribution.forcedErrors,
                                            this.state.insights.scoreDistribution.unforcedErrors,
                                            this.state.insights.scoreDistribution.aces,
                                            this.state.insights.scoreDistribution.dirtyAces,
                                        ]
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Lost Points Distribution</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderScoreDistribution(
                                        [
                                            this.state.insights.lostPointDistribution.winners,
                                            this.state.insights.lostPointDistribution.returnWinners,
                                            this.state.insights.lostPointDistribution.forcedErrors,
                                            this.state.insights.lostPointDistribution.unforcedErrors,
                                            this.state.insights.lostPointDistribution.aces,
                                            this.state.insights.lostPointDistribution.dirtyAces,
                                        ]
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Points Scored</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderActivePassiveScores(
                                        [
                                            this.state.insights.activePointScore.serve,
                                            this.state.insights.activePointScore.passive,
                                            this.state.insights.activePointScore.active
                                        ], 'palette6'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                                <div className={'card-header bg-info text-center'}>
                                    <span>Points Lost</span>
                                </div>
                                <div className={'card-body'}>
                                    {this.renderActivePassiveScores(
                                        [
                                            this.state.insights.activePointLost.serve,
                                            this.state.insights.activePointLost.passive,
                                            this.state.insights.activePointLost.active
                                        ], 'palette6'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return <div className={'content-wrapper'}>
            {this.renderHeader()}
            {this.renderContent()}
        </div>
    }

    async prevYear() {
        await this.setState({year: this.state.year-1})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    async nextYear() {
        await this.setState({year: this.state.year+1})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    async toggleDoubles() {
        await this.setState({doubles: !this.state.doubles})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    renderActivePassiveScores(data, palette) {
        const hasData = this.state.insights.general.matches.garmin > 0
        return (hasData ?
            <ReactApexChart
                options={{
                    theme: {
                        palette: palette
                    },
                    labels: ['Serve', 'Passive', 'Active'],
                    legend: {
                        show: true,
                        position: 'bottom',
                        labels: {
                            useSeriesColors: true,
                        },
                    },
                    dataLabels: {
                        enabled: true
                    },
                    yaxis: {
                        show: false
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            show: false,
                            formatter: () => {
                                return ''
                            },
                            title: {
                                formatter(seriesName) {
                                    switch (seriesName) {
                                        case 'Active':
                                            return seriesName + ' (FE + WIN + RWIN)';
                                        case 'Passive':
                                            return seriesName + ' (UFE + DF)';
                                        case 'Serve':
                                            return seriesName + ' (ACE + DACE)';
                                        default:
                                            return seriesName
                                    }
                                }
                            }
                        }
                    }
                }}
                series={data}
                type={'pie'}
            /> : this.renderNoData()
        )
    }

    renderNoData() {
        return ( <div className="text-center h-100"
                      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span className={'text-muted'}>no-data</span>
        </div>)
    }

    renderScoreDistribution(data) {
        const hasData = this.state.insights.general.matches.garmin > 0
        return ( hasData ?
            <ReactApexChart
                options={{
                    labels: ['Winner', 'Return Winner', 'Forced Error', 'Unforced Error', 'Ace', 'Dirty Ace'],
                    legend: {
                        show: true,
                        position: 'bottom',
                        labels: {
                            useSeriesColors: true,
                        },
                    },
                    dataLabels: {
                        enabled: true
                    },
                    colors: this.palette,
                    yaxis: {
                        show: false
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            show: true,
                            formatter: () => {
                                return ''
                            },
                            title: {
                                formatter(seriesName) {
                                    return seriesName
                                }
                            }
                        },
                    }
                }}
                series={data}
                type={'donut'}
            /> : this.renderNoData()
        )
    }

    renderServeEfficiency(data, labels) {
        const hasData = this.state.insights.general.matches.garmin > 0
        return ( hasData ?
            <ReactApexChart
                options={{
                    // theme: {
                    //     palette: 'palette1'
                    // },
                    colors: this.palette,
                    labels: labels,
                    legend: {
                        show: true,
                        position: 'bottom',
                        labels: {
                            useSeriesColors: true,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -120,
                            endAngle: 120,
                            dataLabels: {
                                total: {
                                    show: true,
                                    label: labels[0],
                                    color: this.palette[0],
                                    formatter: (chart) => {
                                        return chart.config.series[0] + '%'
                                    }
                                }
                            },
                        }
                    },
                }}
                series={data}
                type={'radialBar'}
            /> : this.renderNoData()
        )
    }

    renderRadial(data, labels) {
        const hasData = this.state.insights.general.matches.garmin > 0
        // palette = this.palette
        return ( hasData ?
            <ReactApexChart
                options={{
                    theme: {
                        // palette: palette
                    },
                    colors: this.palette,
                    labels: labels,
                    legend: {
                        show: true,
                        floating: false,
                        position: 'bottom',
                        labels: {
                            useSeriesColors: true,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -120,
                            endAngle: 120,
                            hollow: {
                                size: '35%'
                            },
                            dataLabels: {
                                total: {
                                    show: true,
                                    label: labels[0],
                                    color: this.palette[0],
                                    formatter: (chart) => {
                                        return chart.config.series[0] + '%'
                                    }
                                }
                            },
                        }
                    },
                }}
                series={data}
                type={'radialBar'}
            /> : this.renderNoData()
        )
    }
}

export default withSearchParams(Statistics)