import {Component} from "react";
import Api from "../../../Common/Lib/Api";

class ChangePassword extends Component {

    constructor() {
        super();
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    state = {
        showSaved: false,
        oldPassMessage: '',
        newPassMessage: '',
        repeatPassMessage: '',
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    }
    render () {
        return (<form onSubmit={e => this.handleSubmit(e)}>
            <div className="card card-primary">
                <div className={'card-header'}>
                    <h3 className={'card-title'}>Change Password</h3>
                </div>
                <div className="card-body">
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <label htmlFor={'oldPassword'}>Old password</label>
                            <input value={this.state.oldPassword} name={'oldPassword'} onChange={this.handleInputChange} className={['form-control', !!this.state.oldPassMessage ? 'is-invalid' : undefined].join(' ')}
                                   type={'password'}/>
                            <span className="error invalid-feedback is-invalid">{this.state.oldPassMessage}</span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <label htmlFor={'newPassword'}>New password</label>
                            <input value={this.state.newPassword} name={'newPassword'} onChange={this.handleInputChange} className={['form-control', !!this.state.newPassMessage ? 'is-invalid' : undefined].join(' ')}
                                   type={'password'}/>
                            <span className="error invalid-feedback is-invalid">{this.state.newPassMessage}</span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <label htmlFor={'newPasswordRepeat'}>Repeat new password</label>
                            <input value={this.state.newPasswordRepeat} name={'newPasswordRepeat'} onChange={this.handleInputChange} className={['form-control', !!this.state.repeatPassMessage ? 'is-invalid' : undefined].join(' ')}
                                   type={'password'}/>
                            <span className="error invalid-feedback is-invalid">{this.state.repeatPassMessage}</span>
                        </div>
                    </div>
                </div>
                <div className={'card-footer'}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    {this.state.showSaved ? (<span className={'text-success'}> Saved! <i className="icon fas fa-check-circle"></i></span>) : ''}
                </div>
            </div>
        </form>)
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({
            [name]: target.value
        });
    }
    async handleSubmit (e) {
        e.preventDefault()
        const data = {...this.state}
        const newState = {
            showSaved: false,
            oldPassMessage: '',
            newPassMessage: '',
            repeatPassMessage: '',
        }

        if(this.state.oldPassword.length === 0) {
            newState.oldPassMessage = 'password should not be empty'
        }

        if(this.state.newPassword.length < 8) {
            newState.newPassMessage = 'new password should be longer or equal 8 characters'
        }

        if(this.state.newPassword !== this.state.newPasswordRepeat) {
            newState.repeatPassMessage = 'new password and repeat new password must be the same'
        }

        if(!newState.repeatPassMessage && !newState.newPassMessage && !newState.oldPassMessage) {
            const response = await Api.post('user/changePassword', data)
            if (response.message) {
                newState.oldPassMessage = 'Wrong password';
            } else {
                newState.showSaved = true;
                newState.oldPassword = ''
                newState.newPassword = ''
                newState.newPasswordRepeat = ''
            }
        }
        this.setState(newState)
    }
}

export default ChangePassword