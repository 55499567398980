import {Component} from "react";
import Matches from "../Main/Matches/Matches";
import Profile from "../Main/Profile/Profile";
import Details from "../Main/Match/Details";
import Login from "../Common/Login";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Main from "../Main/Main";
import Register from "../Common/Register";
import Activate from "../Common/Activate";
import ResetPassword from "../Common/ResetPassword";
import ResetPasswordRequest from "../Common/ResetPasswordRequest";
import ResendActivationLink from "../Common/ResendActivationLink";
import GuestLayout from "./GuestLayout";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import Dashboard from "../Main/Dashboard/Dashboard";
import Statistics from "../Main/Dashboard/Statistics";
import Practices from "../Main/Practices/Practices";
ChartJS.register(ArcElement, Tooltip, Legend, Title);


class App extends Component {
  render() {
          return (
                  <BrowserRouter>
                      <Routes>
                          <Route index element={<Navigate to='/dashboard' />} />
                          <Route path='/matches' element={<Main content={<Matches />}/>} />
                          <Route path='/practices' element={<Main content={<Practices />}/>} />
                          <Route path='/dashboard' element={<Main content={<Dashboard />}/>} />
                          <Route path='/statistics' element={<Main content={<Statistics />}/>} />
                          <Route path='/profile' element={<Main content={<Profile />}/>} />
                          <Route path='/match/:matchId' element={<Main content={<Details />}/>} />
                          <Route path='/login' element={<Login />} />
                          <Route path='/register' element={<Register />} />
                          <Route path='/user/activate/:token' element={<Activate />} />
                          <Route path='/user/reset-password/:token' element={<ResetPassword />} />
                          <Route path='/user/reset-password-request' element={<ResetPasswordRequest />} />
                          <Route path='/user/resend-activation-link' element={<ResendActivationLink />} />
                          <Route path='/guest/match/:matchId' element={<GuestLayout content={<Details isGuest={true} />}/>} />
                          <Route path='/*' element={<Navigate to='/dashboard' />} />
                      </Routes>
                  </BrowserRouter>
          );
  }
}

export default App;
