import {Component} from "react";
import Api from "../../../Common/Lib/Api";
import {getUser, refreshUser, setUser} from "../../../Common/Storage";

export default class PlayerDetails extends Component {
    constructor() {
        super()
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    state = {
        showSaved: false,
        name: ''
    }
    async componentDidMount() {
        await refreshUser()
        const user = getUser()
        this.setState({name: user.name})
    }

    render () {
        return (<form onSubmit={e => this.handleSubmit(e)}>
            <div className="card card-primary">
                <div className={'card-header'}>
                    <h3 className={'card-title'}>Me myself and I</h3>
                </div>
                <div className="card-body">
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <label htmlFor={'oldPassword'}>Display name</label>
                            <input value={this.state.name} name={'name'} onChange={this.handleInputChange} className={['form-control'].join(' ')}
                                   type={'text'}/>
                            <span className="error invalid-feedback is-invalid">{this.state.oldPassMessage}</span>
                        </div>
                    </div>
                </div>
                <div className={'card-footer'}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    {this.state.showSaved ? (<span className={'text-success'}> Saved! <i className="icon fas fa-check-circle"></i></span>) : ''}
                </div>
            </div>
        </form>)
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({
            [name]: target.value
        });
    }
    async handleSubmit (e) {
        e.preventDefault()
        const data = {...this.state}
        const newState = {
            showSaved: false,
        }
        const user = await Api.post('user/update', data)
        setUser(user)
        newState.showSaved = true;
        this.setState(newState)
    }
}