import {Component} from "react";
import StatsRow from "./StatsRow";
import StatsRowHeader from "./StatsRowHeader";

export default class ServeReturnTab extends Component {
   render() {
       const p1Stats = this.props.playerStats.p1Stats || {}
       const p2Stats = this.props.playerStats.p2Stats || {}
       return (
           <table className="table table-borderless">
               <tbody>
               <StatsRowHeader
                   header={'Accuracy'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve1.serveIn +  p1Stats.serve2.serveIn,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve1.serveIn +  p2Stats.serve2.serveIn,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Serve In'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve1.serveIn,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve1.serveIn,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'1st Serve In'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve2.serveIn,
                       total:  p1Stats.serve2.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve2.serveIn,
                       total:  p2Stats.serve2.attempts
                   }}
                   label={'2nd Serve In'} />
               <StatsRowHeader
                   header={'Serve'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve.aces,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve.aces,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Aces'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.8.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve.dirtyAces,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve.dirtyAces,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Dirty Aces'}
                   tooltip={`Serves that returner wasn't able to return`}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.8.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve.dirtyAces +  p1Stats.serve.aces,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve.dirtyAces +  p2Stats.serve.aces,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Serve Efficiency'}
                   tooltip={'Aces + Dirty Aces / Serves'}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve.doubleFaults,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve.doubleFaults,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Double Faults'} />


               <StatsRowHeader
                   header={'Return'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.return.points,
                       total:  p1Stats.return.attempts
                   }}
                   p2={{
                       value:  p2Stats.return.points,
                       total:  p2Stats.return.attempts
                   }}
                   label={'Point'} />

               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.return.returnWinners,
                       total:  p1Stats.return.attempts
                   }}
                   p2={{
                       value:  p2Stats.return.returnWinners,
                       total:  p2Stats.return.attempts
                   }}
                   label={'Winner'}
                   tooltip={'Point scored as a winner directly from return.'}
               />

               <StatsRowHeader
                   header={'Points'}
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve1.points,
                       total:  p1Stats.serve1.serveIn
                   }}
                   p2={{
                       value:  p2Stats.serve1.points,
                       total:  p2Stats.serve1.serveIn
                   }}
                   label={'1st Serve Point'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve2.points,
                       total:  p1Stats.serve2.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve2.points,
                       total:  p2Stats.serve2.attempts
                   }}
                   label={'2nd Serve Point'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.serve1.points +  p1Stats.serve2.points,
                       total:  p1Stats.serve1.attempts
                   }}
                   p2={{
                       value:  p2Stats.serve1.points +  p2Stats.serve2.points,
                       total:  p2Stats.serve1.attempts
                   }}
                   label={'Serve points won'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.return.points,
                       total:  p1Stats.return.attempts
                   }}
                   p2={{
                       value:  p2Stats.return.points,
                       total:  p2Stats.return.attempts
                   }}
                   label={'Return points won'} />
               </tbody>
           </table>
       )
   }
}