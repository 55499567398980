import React, {Component} from "react";

import Api from "../../Common/Lib/Api";
import Loading from "../../Common/Loading";
import ReactApexChart from 'react-apexcharts'
import {createBadge} from "../../Common/Badges/BadgeFactory";
import {GAME_BADGES, MATCH_BADGES} from "../../Common/Badges/Badges";
import withSearchParams from "../../Common/Component/withSearchParams";
import {getConfiguration} from "../../Common/Storage";
import WarningBar from "../../Common/WarningBar";

class Dashboard extends Component {
    colors = {
        green: '#00E396',
        red: '#FF4560',
        yellow: '#FEB019',
        blue: '#008FFB',
        purple: '#775DD0',
        lightRed: '#D4526E',
        darkBlue: '#D4526E'
    }

    palette = []

    state = {
        isLoading: true,
        year: null,
        doubles: false,
        insights: {}
    }

    constructor() {
        super();
        this.palette = Object.values(this.colors)
    }

    async componentDidMount() {
        await this.setState({
            year: parseInt(this.props.searchParams.get('year') || new Date().getFullYear(), 10),
            doubles: this.props.searchParams.get('doubles') === 'true'
        })
        await this.loadData()
    }

    async loadData() {
        this.setState({isLoading: true})
        const insights = (await Api.get(`insights?year=${this.state.year}&doubles=${this.state.doubles}`))
        this.setState({insights, isLoading: false})
    }

    renderNoLicenseBar() {
        const configuration = getConfiguration()
        const hasAccess = configuration.licenses.filter(license => license.status === 'active' && license.product === 'scoreTracker').length > 0

        return !hasAccess ? (
            <WarningBar type="danger" header="Warning" text={`You are not a <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a> subscriber, so you have limited funcitonality here.<br/>
                To enable the full funcitonality for this page, please subscribe <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a>`}/>
        ) : undefined
    }

    renderHeader() {
        return (<div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <h1 className="m-0">Dashboard</h1>
                    </div>
                </div>
                {this.renderNoLicenseBar()}
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <div className={'btn-group'}>
                            <button className={'btn btn-primary'} onClick={() => this.prevYear()}>{'<'}</button>
                            <div className={'btn btn-primary'}>{this.state.year}</div>
                            <button className={'btn btn-primary'} onClick={() => this.nextYear()}>{'>'}</button>
                        </div>
                        <div className={'btn-group'} style={{margin: '0 10px'}}>
                            <button className={'btn btn-primary'} onClick={() => this.toggleDoubles()}>{'<'}</button>
                            <div className={'btn btn-primary'}>{this.state.doubles ? 'Doubles' : 'Singles'}</div>
                            <button className={'btn btn-primary'} onClick={() => this.toggleDoubles()}>{'>'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    renderContent() {
        return this.state.isLoading ? <Loading/> : (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 py-2">
                            <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                <span>Totals</span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderIndicator()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 py-2">
                        <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                <span>Achievements</span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderAchievements()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 py-2">
                        <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                <span className={'amr-tooltip amr-tooltip-label'}>Conversion <span
                                    className={'amr-tooltiptext'}>Does not include matches added directly in dashboard ({this.state.insights.general.matches.garmin} out of {this.state.insights.general.matches.all})</span> </span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderIndicatorPerMatch()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-3 py-2">
                        <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                    <span className={'amr-tooltip amr-tooltip-label'}>Serve Games Consistency<span
                                        className={'amr-tooltiptext'}>Does not include matches added directly in dashboard ({this.state.insights.general.matches.garmin} out of {this.state.insights.general.matches.all})</span> </span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderIndicatorServes()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 py-2">
                        <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                <span>Activities</span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderMatchChart(this.state.insights.matchesTimeline)}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 py-2">
                        <div className={'card h-100'}>
                            <div className={'card-header bg-info text-center'}>
                                <span className={'amr-tooltip amr-tooltip-label'}>Return Games Consistency<span
                                    className={'amr-tooltiptext'}>Does not include matches added directly in dashboard ({this.state.insights.general.matches.garmin} out of {this.state.insights.general.matches.all})</span> </span>
                            </div>
                            <div className={'card-body'}>
                                {this.renderIndicatorReturn()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {
        return <div className={'content-wrapper'}>
            {this.renderHeader()}
            {this.renderContent()}
        </div>
    }

    async prevYear() {
        await this.setState({year: this.state.year-1})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    async nextYear() {
        await this.setState({year: this.state.year+1})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    async toggleDoubles() {
        await this.setState({doubles: !this.state.doubles})
        this.props.setSearchParams({
            year: this.state.year,
            doubles: this.state.doubles
        })
        await this.loadData()
    }

    renderAchievements() {
        return (<div className={'row'} style={{justifyContent: 'space-evenly'}}>
            {createBadge(GAME_BADGES.PURE_WIN, {
                count: this.state.insights.allBadges.games.pureWin,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(GAME_BADGES.SHARP_SHOOTER, {
                count: this.state.insights.allBadges.games.sharpShooter,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(GAME_BADGES.SURVIVOR, {
                count: this.state.insights.allBadges.games.survivor,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(MATCH_BADGES.GAMER, {
                count: this.state.insights.allBadges.match.gamer,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(MATCH_BADGES.BREAKER, {
                count: this.state.insights.allBadges.match.breaker,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(MATCH_BADGES.DOUBLE_BAGEL, {
                count: this.state.insights.allBadges.match.doubleBagel,
                showName: true,
                additionalClass: 'big'
            })}
            {createBadge(MATCH_BADGES.BAGEL, {
                count: this.state.insights.allBadges.match.bagel,
                showName: true,
                additionalClass: 'big'
            })}
        </div>)
    }

    renderIndicator() {
        return (
            <div className="text-center h-100"
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{fontSize: '2rem'}}>
                    <p>
                        <span className={'text-primary'}>Matches: </span><span
                        className={'text-success'}>{this.state.insights.general.matches.won} Won</span> / <span
                        className={'text-danger'}>{this.state.insights.general.matches.lost} Lost</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Sets: </span><span
                        className={'text-success'}>{this.state.insights.general.sets.won} Won</span> / <span
                        className={'text-danger'}>{this.state.insights.general.sets.lost} Lost</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Games: </span><span
                        className={'text-success'}>{this.state.insights.general.games.won} Won</span> / <span
                        className={'text-danger'}>{this.state.insights.general.games.lost} Lost</span>
                    </p>
                </div>
            </div>
        )
    }

    renderIndicatorPerMatch() {
        const breakPointsPercent = this.state.insights.general.breakPoints.total > 0 ? Math.round((this.state.insights.general.breakPoints.won / this.state.insights.general.breakPoints.total)*100) : 0
        const setPointsPercent = this.state.insights.general.setPoints.total > 0 ? Math.round((this.state.insights.general.setPoints.won / this.state.insights.general.setPoints.total)*100) : 0
        const matchPointsPercent = this.state.insights.general.matchPoints.total > 0 ? Math.round((this.state.insights.general.matchPoints.won / this.state.insights.general.matchPoints.total)*100) : 0
        return (
            <div className="text-center h-100"
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{fontSize: '2rem'}}>
                    <p>
                        <span className={'text-primary'}>Break Points: </span><span
                        className={this.getRangeColorsGreater(breakPointsPercent, [45, 55])}>{this.state.insights.general.breakPoints.won}</span> / <span className={'text-success'}>{this.state.insights.general.breakPoints.total}</span> (<span className={this.getRangeColorsGreater(breakPointsPercent, [40, 55])} > {breakPointsPercent}%</span>)
                    </p>
                    <p>
                        <span className={'text-primary'}>Set Points: </span><span
                        className={this.getRangeColorsGreater(setPointsPercent, [45, 55])}>{this.state.insights.general.setPoints.won}</span> / <span className={'text-success'}>{this.state.insights.general.setPoints.total}</span> (<span className={this.getRangeColorsGreater(setPointsPercent, [40, 55])} > {setPointsPercent}%</span>)
                    </p>
                    <p>
                        <span className={'text-primary'}>Match Points: </span><span
                        className={this.getRangeColorsGreater(matchPointsPercent, [45, 55])}>{this.state.insights.general.matchPoints.won}</span> / <span className={'text-success'}>{this.state.insights.general.matchPoints.total}</span> (<span className={this.getRangeColorsGreater(matchPointsPercent, [40, 55])} > {matchPointsPercent}%</span>)
                    </p>
                </div>
            </div>
        )
    }

    renderIndicatorServes() {
        const stats = this.state.insights.serveGamesConsistency
        const pointsPercent = stats.points.total > 0 ? Math.round((stats.points.scored / stats.points.total) * 10000) / 100 : 0
        const avgGameDuration = stats.points.total > 0 ? Math.round((stats.points.total / stats.games.total) * 100) / 100 : 0
        const gamesTotalPercent = stats.games.total > 0 ? Math.round((stats.games.won / stats.games.total) * 10000) / 100 : 0
        return (
            <div className="text-center h-100"
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{fontSize: '2rem'}}>
                    <p>
                        <span className={'text-primary'}>Points Won: </span>
                        <span
                            className={this.getRangeColorsGreater(pointsPercent, [45, 65])}> {pointsPercent}%</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Avg Length: </span><span
                        className={'text-primary'}>{avgGameDuration} pts</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Games Held: </span>
                        <span
                            className={this.getRangeColorsGreater(gamesTotalPercent, [45, 65])}> {gamesTotalPercent}%</span>
                    </p>
                </div>
            </div>
        )
    }

    renderIndicatorReturn() {
        const stats = this.state.insights.returnGamesConsistency
        const pointsPercent = stats.points.total > 0 ? Math.round((stats.points.scored / stats.points.total) * 10000) / 100 : 0
        const avgGameDuration = stats.points.total > 0 ? Math.round((stats.points.total / stats.games.total) * 100) / 100 : 0
        const gamesTotalPercent = stats.games.total > 0 ? Math.round((stats.games.won / stats.games.total) * 10000) / 100: 0
        return (
            <div className="text-center h-100"
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{fontSize: '2rem'}}>
                    <p>
                        <span className={'text-primary'}>Points Won: </span>
                        <span className={this.getRangeColorsGreater(pointsPercent, [25, 45])}> {pointsPercent}%</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Avg Length: </span><span
                        className={'text-primary'}>{avgGameDuration} pts</span>
                    </p>
                    <p>
                        <span className={'text-primary'}>Games Broken: </span>
                        <span className={this.getRangeColorsGreater(gamesTotalPercent, [25, 40])}> {gamesTotalPercent}%</span>
                    </p>
                </div>
            </div>
        )
    }

    getRangeColorsGreater(value, ranges) {
        switch(true) {
            case value > ranges[1]:
                return 'text-success'
            case value > ranges[0]:
                return 'text-warning'
            default:
                return 'text-danger'
        }
    }
    getRangeColorsLower(value, ranges) {
        switch(true) {
            case value < ranges[1]:
                return 'text-success'
            case value < ranges[0]:
                return 'text-warning'
            default:
                return 'text-danger'
        }
    }

    renderMatchChart(matchesTimeline) {
        const options = {
            chart: {
                stacked: true,
                toolbar: {
                    show: false
                },
            },
            colors: ['#00aeff', '#00E396', '#FF4560'],
            responsive: [{
                options: {
                    legend: {
                        position: 'top',
                        // offsetX: -10,
                        // offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: matchesTimeline.map(match => match.label)
            },
            legend: {
                position: 'top',
                // offsetY: 40
            },
            fill: {
                opacity: 1
            }
        }
        return (
            <ReactApexChart
                series={[
                    {name: 'PRACTICE', data: matchesTimeline.map(match => match.values.practices)},
                    {name: 'WON', data: matchesTimeline.map(match => match.values.matchesWon)},
                    {name: 'LOST', data: matchesTimeline.map(match => match.values.matchesLost)}
                ]}
                type={'bar'}
                options={options}
                height={'300px'}
            />
        )
    }
}

export default withSearchParams(Dashboard)