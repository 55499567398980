import {Component} from "react";
import MatchesTable from "./Components/MatchesTable";
import Api from "../../Common/Lib/Api";
import moment from "moment";
import PerformanceTable from "./Components/PerformanceTable";
import FiltersSection from "./Components/FiltersSection";
import ByOpponentTable from "./Components/ByOpponentTable";
import Loading from "../../Common/Loading";
import WarningBar from "../../Common/WarningBar";
import withSearchParams from "../../Common/Component/withSearchParams";
import {getConfiguration} from "../../Common/Storage";
class Matches extends Component {
    state = {
        isLoading: false,
        matches: [],
        filteredMatches: [],
        thisYearMatches: [],
        filters: {
            player: 'all',
            matchType: 'all',
            surface: 'all',
            dateFrom: moment('2023-01-01').format('YYYY-MM-DD'),
            dateTo: moment().endOf('year').format('YYYY-MM-DD'),
            tags: {
                and: [],
                or: [],
                exluded: []
            },
            showUnfinished: false,
        }
    }
    async componentDidMount() {
        this.setState({isLoading: true})
        const [allMatches] = await Promise.all([Api.get('match/list')])
        // if(false) {
        //     allMatches.map(match => {
        //         match.opponent = fakeName()
        //         match.tags = randomTags()
        //         return match
        //     })
        // }
        allMatches.sort((match1, match2) => {return moment(match1.matchDate).isBefore(moment(match2.matchDate)) ? 1 : -1})
        const matches = allMatches.map(match => {
            match.surface = match.surface.toLowerCase()
            return match
        })
        this.setState({matches, isLoading: false, filters: this.searchParamsToFilter()})
        this.applyfilters(this.state.filters)
    }

    applyfilters(filters) {
        let filteredMatches = [...this.state.matches]

        if(filters.player !== 'all' && filters.player !== '') {
           filteredMatches = filteredMatches.filter(match => match.opponent.toLowerCase() === filters.player.toLowerCase() || match.doubleOpponent.toLowerCase() === filters.player.toLowerCase())
        }
        if(filters.matchType !== 'all') {
            filteredMatches = filteredMatches.filter(match => match.rules.toLowerCase() === filters.matchType.toLowerCase())
        }
        if(filters.surface !== 'all') {
            filteredMatches = filteredMatches.filter(match => match.surface.toLowerCase() === filters.surface.toLowerCase())
        }

        if(filters.showUnfinished === false) {
            filteredMatches = filteredMatches.filter(match => match.matchResult !== 0)
        }

        filteredMatches = filteredMatches.filter(match => !!match.isDouble === !!filters.doubles)

        filteredMatches = this.applyTagsFilters(filteredMatches, filters.tags)

        filteredMatches = filteredMatches.filter(match => moment(match.matchDate).isAfter(moment(filters.dateFrom)))
        filteredMatches = filteredMatches.filter(match => moment(match.matchDate).isBefore(moment(filters.dateTo)))
        filteredMatches.sort((match1, match2) => {return moment(match1.matchDate).isBefore(moment(match2.matchDate)) ? 1 : -1})
        this.setState({filteredMatches, filters})
        this.updateSearchParams(filters)
    }

    updateSearchParams(filters) {
        const searchParams = {...filters}
        const {and, or, exluded} = filters.tags
        delete searchParams.tags
        searchParams.tagsAnd = and.join(',')
        searchParams.tagsOr = or.join(',')
        searchParams.tagsExluded = exluded.join(',')
        this.props.setSearchParams(searchParams)
    }

    searchParamsToFilter() {
        return {
            player: this.props.searchParams.get('player') || 'all',
            matchType: this.props.searchParams.get('matchType') || 'all',
            surface: this.props.searchParams.get('surface') || 'all',
            dateFrom: this.props.searchParams.get('dateFrom') || moment('2023-01-01').startOf('year').format('YYYY-MM-DD'),
            dateTo: this.props.searchParams.get('dateTo') ||moment().endOf('year').format('YYYY-MM-DD'),
            tags: {
                and: (this.props.searchParams.get('tagsAnd') || '').split(',').filter(item => !!item) || [],
                or: (this.props.searchParams.get('tagsOr') || '').split(',').filter(item => !!item) || [],
                exluded: (this.props.searchParams.get('tagsExluded') || '').split(',').filter(item => !!item) || []
            },
            showUnfinished: this.props.searchParams.get('showUnfinished') === 'true',
        }
    }

    applyTagsFilters(filteredMatches, filters) {
        let andMatches = []
        let orMatches = []

        if(filters.exluded.length > 0) {
            filteredMatches = [...filteredMatches].filter(match => filters.exluded.map(tag => match.tags.includes(tag)).filter(tag => !!tag).length === 0)
        }

        if(filters.and.length === 0 && filters.or.length === 0) {
            return filteredMatches
        }

        if(filters.and.length > 0) {
            andMatches = [...filteredMatches].filter(match =>
                filters.and.map(tag => match.tags.includes(tag)).filter(tag => !!tag).length === filters.and.length
            )
        }
        if(filters.or.length > 0) {
            orMatches = [...filteredMatches].filter(match => filters.or.map(tag => match.tags.includes(tag)).filter(tag => !!tag).length > 0)
        }

        const filteredTagMatches = [...andMatches, ...orMatches].filter((match, index, array) => array.indexOf(match) === index)
        return filteredTagMatches
    }

    renderWarningBar() {
        const configuration = getConfiguration()
        const isConnected = configuration.devices.filter(device => device.appKey === 'tennisTracker' && device.isConnected === true).length > 0

        return !isConnected ? (
            <WarningBar type="info" header="Info" text={`It seems to the <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a> app is not connected to this dashboard.<br/>To be able to automatically sync the matches from your watch app,
                please go to the <a href="/profile">profile</a> section and connect your app to the cloud by following the insturctions: <a target="_blank" href="https://allmyrackets.com/cloud-sync">https://allmyrackets.com/cloud-sync</a>`}/>
        ) : undefined
    }

    renderNoLicenseBar() {
        const configuration = getConfiguration()
        const hasAccess = configuration.licenses.filter(license => license.status === 'active' && license.product === 'scoreTracker').length > 0

        return !hasAccess ? (
            <WarningBar type="danger" header="Warning" text={`You are not a <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a> subscriber, so you have limited funcitonality here. You can't add and modify matches, you can only view the matches that you've created before the license expiration.<br/>
                To enable the full funcitonality for this page, please subscribe <a target="_blank" href="https://apps.garmin.com/apps/67ab553e-3426-4857-b3f9-25ebb4d9632d" >AMR Tennis Tracker</a>`}/>
        ) : undefined
    }

    render() {
        const onFilterChange = (filters) => {
            this.applyfilters(filters)
        }
        return this.state.isLoading ? <Loading /> : (<div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <h1 className="m-0">Matches</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="container-fluid">
                {this.renderNoLicenseBar()}
                {this.renderWarningBar()}
                <div className="row">
                    <div className="col-lg-12">
                        <FiltersSection matches={this.state.matches} onFilterChange={onFilterChange} defaults={this.state.filters} />
                    </div>
                </div>
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <MatchesTable matches={this.state.filteredMatches} title={'Matches'} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ByOpponentTable matches={this.state.filteredMatches} title={'Opponents'} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <PerformanceTable title={'Performance'} matches={this.state.filteredMatches}/>
                    </div>
                </div>
            </div>
        </div>
    </div>)
    }
}
//
// function fakeName () {
//     const names = [
//         'John Doe',
//         'Justin Case',
//         'Ronny Beckett',
//         'Pierce Kaison',
//         'Theodore Webster',
//         'John Brenton',
//         'Mati Buddy',
//         'Adi Kontny'
//     ]
//     const index = Math.floor(Math.random() * names.length);
//     return names[index]
// }
//
// function randomTags() {
//     const tags = [
//         ['tournament', 'dover'],
//         ['tournament', 'cardiff'],
//         ['tournament', 'london'],
//         ['league', 'london', 'division1'],
//         ['friendly'],
//     ]
//     const index = Math.floor(Math.random() * tags.length);
//     return tags[index]
// }

export default withSearchParams(Matches)