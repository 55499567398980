import {Component} from "react";
import StatsRow from "./StatsRow";
import StatsRowHeader from "./StatsRowHeader";

export default class GeneralTab extends Component {
   render() {
       const p1Stats = this.props.playerStats.p1Stats || {}
       const p2Stats = this.props.playerStats.p2Stats || {}
       const totals = {
           sets: {
               won: this.props.match.details.setsWon,
               lost: this.props.match.details.setsLost,
               total: this.props.match.details.setsWon + this.props.match.details.setsLost,
           },
           games: {
               won: this.props.match.details.gamesWon,
               lost: this.props.match.details.gamesLost,
               total: this.props.match.details.gamesWon + this.props.match.details.gamesLost,
           },
       }
       return (
           <table className="table table-borderless">
               <tbody>
               <StatsRowHeader
                   header={'Match'}
                   currentVersion={this.props.match.version}
                   fromVersion={'0.0.0'}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                    p1={{ value:  p1Stats.points, total:  p1Stats.points + p2Stats.points }}
                    p2={{ value: p2Stats.points,total:  p1Stats.points + p2Stats.points }}
                    label={'Points'} />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'0.0.0'}
                   label={'Sets'}
                   p1={{value: totals.sets.won,total: totals.sets.total}}
                   p2={{value: totals.sets.lost, total: totals.sets.total}}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'0.0.0'}
                   label={'Games'}
                   p1={{value: totals.games.won,total: totals.games.total}}
                   p2={{value: totals.games.lost, total: totals.games.total}}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   label={'Breaks'}
                   p1={{value: p1Stats.breakPointsWon, total: p1Stats.breakPointsWon + p2Stats.breakPointsWon}}
                   p2={{value: p2Stats.breakPointsWon, total: p1Stats.breakPointsWon + p2Stats.breakPointsWon}}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   label={'Games Saved'}
                   tooltip={'The returner had opportunity to break the game but the server finally won the game'}
                   p1={{value: p1Stats.gamesDefended, total: p1Stats.gamesDefended + p2Stats.gamesDefended}}
                   p2={{value: p2Stats.gamesDefended, total: p1Stats.gamesDefended + p2Stats.gamesDefended}}
               />
               <StatsRowHeader header={'Important Points'} fromVersion={'1.0.0'} currentVersion={this.props.match.version}/>
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   showTotal={true}
                   label={'Break Points Won'}
                   p1={{
                       value:  p1Stats.breakPointsWon,
                       total:  p1Stats.breakPoints
                   }}
                   p2={{
                       value: p2Stats.breakPointsWon,
                       total: p2Stats.breakPoints
                   }}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   label={'Game Points Won'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.gamePointsWon,
                       total:  p1Stats.gamePoints
                   }}
                   p2={{
                       value: p2Stats.gamePointsWon,
                       total: p2Stats.gamePoints
                   }}
               />
               {
                   this.props.match.rules === 'Short' || this.props.match?.rulesDescription?.goldenShot === true ? (<StatsRow
                   currentVersion={this.props.match.version}
               fromVersion={'1.0.0'}
               label={'Golden Points Won'}
               showTotal={true}
               p1={{
                   value:  p1Stats.goldenPointsWon,
                   total:  p1Stats.goldenPoints
               }}
               p2={{
                   value: p2Stats.goldenPointsWon,
                   total: p2Stats.goldenPoints
               }}
               />) : undefined
               }
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   label={'Set Points Won'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.setPointsWon,
                       total:  p1Stats.setPoints
                   }}
                   p2={{
                       value: p2Stats.setPointsWon,
                       total: p2Stats.setPoints
                   }}
               />
               <StatsRow
                   currentVersion={this.props.match.version}
                   fromVersion={'1.0.0'}
                   label={'Match Points Won'}
                   showTotal={true}
                   p1={{
                       value:  p1Stats.matchPointsWon,
                       total:  p1Stats.matchPoints
                   }}
                   p2={{
                       value: p2Stats.matchPointsWon,
                       total: p2Stats.matchPoints
                   }}
               />
               </tbody>
           </table>
       )
   }
}