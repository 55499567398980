import {Component} from "react";
import getSession from './Session'
import Api from "./Lib/Api";
import Loading from "./Loading";
import {Navigate} from "react-router-dom";

class ResetPasswordRequest extends Component {
    constructor() {
        super();
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    async resetPasswordRequest(data) {
        this.setState({isLoading: true})
        const response = await Api.post('/user/resetPasswordRequest', {
            email: data.email,
        }, {token: null})
        this.setState({isLoading: false})
        return response
    }

    state = {
        isLoading: true,
        isLoggedIn: false,
        email: '',
        emailValidationMessage: '',
        generalErrorMessage: '',
        generalInfoMessage: '',
    }
    async componentDidMount() {
        const session = getSession()
        const accessToken = await session.getAccessToken()
        this.setState({isLoggedIn: !!accessToken, isLoading: false})
    }

    render() {
        if(this.state.isLoading) {
            return <Loading />
        } else {
            return this.state.isLoggedIn ? <Navigate to="/dashboard" replace={true} />
                : (<div className="hold-transition login-page">
                    <div className="login-box">
                        <div className="card card-outline card-primary">
                            <div className="card-body">
                                <p className="login-box-msg">Reset Password</p>
                                <span id={'generalErrorMessage'} className="text-danger">{this.state.generalErrorMessage}</span>
                                <span id={'generalInfoMessage'} className="text-success">{this.state.generalInfoMessage}</span>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="input-group mb-3">
                                        <input name={'email'} type="email" aria-describedby={'emailValidationMessage'} value={this.state.email} className={this.formClasses('emailValidationMessage')} placeholder="Email" onChange={e => this.setState({email: e.target.value})}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                        {this.validationMessage('emailValidationMessage')}
                                    </div>
                                    <div className={'input-group mb-3'}>
                                            <button type="submit" className="btn btn-primary btn-block">Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }
    handleInputChange(e) {
        const target = e.target;
        let value = target.type === 'checkbox' ? !!target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async e => {
        e.preventDefault()
        const data = {...this.state}
        let isValid = true;
        const newState = {
            emailValidationMessage: '',
            generalErrorMessage: '',
            generalInfoMessage: '',
        };

        if(this.state.email.length === 0) {
            isValid = false
            newState.emailValidationMessage = 'Email should not be empty'
        }

        if(isValid) {
            const result = await this.resetPasswordRequest(data);
            if(result.statusCode === 400) {
                newState.generalErrorMessage = result.message
            } else if(result.success) {
                newState.generalInfoMessage = `Request sent. Please check your email and follow the instructions`
                newState.email = ''
            }
        }
        this.setState(newState);
    }

    formClasses(field) {
        return ['form-control', this.state[field] ? 'is-invalid' : undefined].join(' ')
    }
    validationMessage(field) {
        return (<span id={field} className="error invalid-feedback is-invalid">{this.state[field]}</span>)
    }
}

export default ResetPasswordRequest