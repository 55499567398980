import Api from "./Lib/Api";
let configuration = {}
export function getUser() {
    const savedUser = sessionStorage.getItem('User')
    return savedUser ? JSON.parse(savedUser) : {}
}

export function setUser(user) {
    sessionStorage.setItem('User', JSON.stringify(user))
}

export function getConfiguration() {
    return {...configuration};
}

export async function refreshConfiguration() {
    configuration = await Api.get('user/configuration')
}
export async function refreshUser() {
    const user = await Api.get('user/profile')
    setUser(user)
}