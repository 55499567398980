import {Component} from "react";

class PureWinSvg extends Component {
    render() {
        return (<svg
            className={this.props.className}
            style={this.props.style}
            key={this.props.uniqKey}
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 24 24'}
        >
            <path id="primary"
                  d="M16.6,2.2a1,1,0,0,0-1-.14l-8,3a1,1,0,0,0-.65,1l1,11A1,1,0,0,0,9,18h6a1,1,0,0,0,1-.93l1-14A1,1,0,0,0,16.6,2.2Z"
                  style={{fill: 'rgb(241,235,21)'}}></path>
            <path id="secondary" d="M16,17H8a2,2,0,0,0-2,2v2a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V19A2,2,0,0,0,16,17Z"
                  style={{fill: 'rgb(41,47,170)'}}></path>
        </svg>)

    }
}

export default PureWinSvg