import {Component} from "react";

class TablePaging extends Component {
    render() {
        const pagesCount = Math.ceil(this.props.items / this.props.perPage)
        const pagingItems = []
        for(let pageNumber=1; pageNumber<=pagesCount; pageNumber++) {
            const pageClassName = `page-item${pageNumber === this.props.page ? ' active' : ''}`
            pagingItems.push(<li key={pageNumber} className={pageClassName}><a className="page-link" href="#" data-page={pageNumber} onClick={this.props.onPageChange}>{pageNumber}</a></li>)
        }
        return (<ul className="pagination pagination-sm m-0 float-right">
            <li className="page-item"><a className="page-link" data-page={'first'} onClick={this.props.onPageChange} href="#">«</a></li>
            {pagingItems}
            <li className="page-item"><a className="page-link" data-page={'last'} onClick={this.props.onPageChange} href="#">»</a></li>
        </ul>)}
}

export default TablePaging
