import {Component} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import TablePaging from "./TablePaging";
import AddPracticeForm from "./AddPracticeForm";
import RemovePracticeDialog from "./RemovePracticeDialog";

class PracticesTable extends Component {
    state = {
        page: 1,
        perPage: 10,
        currentPractice: {
            id: '',
            date: moment().format('YYYY-MM-DD'),
            comment: '',
            duration: 0,
            tags: [],
            source: 'Web',
        }
    }

    render() {
        // const players = this.props.matches.map(match => match.opponent).filter((opponent, index, array) => array.indexOf(opponent) === index)
        const offset = (this.state.page * this.state.perPage) - this.state.perPage
        const limit = offset + this.state.perPage
        const getColor = () => {
            const colors = ['blue']
            return colors[Math.floor(Math.random() * (colors.length)) + 0]
        }
        return (<div className="card">
            <div className={'card-header'}>
                <h3 className="card-title">{this.props.title} {`${offset+1} - ${limit < this.props.practices.length ? limit : this.props.practices.length } of ${this.props.practices.length}`}</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                </div>
            </div>

            <div className="card-body table-responsive p-0">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Tags</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.props.practices.map(practice => {
                        // const badgeClass = `badge badge-${match.matchResult === 1 ? 'success' : match.matchResult === 0 ? 'primary' : 'danger'}`
                        return (<tr key={practice._id.toString()}>
                                <td>{moment(practice.date).format('DD.MM.YYYY')}
                                </td>
                                <td>{this.formatDuration(practice.duration)}</td>
                                <td style={{maxWidth: '80px'}}>{practice.tags.sort().map(tag => (
                                    <span key={`${practice._id.toString()}${tag}`} style={{margin: '0 2px'}}
                                          className={`badge bg-${getColor()}`}>{tag}</span>))}
                                </td>
                                <td style={{maxWidth: '150px'}}>{practice.comment}</td>

                                <td>
                                    {/*<Link style={{margin: '0 5px 0 0'}} className={'btn btn-xs btn-primary'}*/}
                                    {/*      to={`/match/${practice._id.toString()}`}>*/}
                                    {/*    <i className={`fas ${practice.source === 'Garmin' ? 'fa-chart-bar' : 'fa-location-arrow'}`}></i>*/}
                                    {/*</Link>*/}
                                    <span style={{margin: '0 5px 0 0'}} className="btn btn-xs btn-primary"
                                          onClick={(e) => this.setPractice(practice)}>
                                        <i className="fas fa-pen" data-toggle="modal" data-target="#practice-modal"></i>
                                    </span>
                                    <span style={{margin: '0 5px 0 0'}} className="btn btn-xs btn-danger"
                                          onClick={(e) => this.setPractice(practice)}>
                                        <i className="fas fa-trash" data-toggle={'modal'}
                                           data-target={'#remove-practice-modal'}></i> </span>
                                </td>
                            </tr>
                        )
                    }).slice(offset, limit)}
                    </tbody>
                </table>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#practice-modal"
                        onClick={(e) => this.clearPractice()}>
                    Add Practice
                </button>
                <AddPracticeForm practice={this.state.currentPractice} />
                <RemovePracticeDialog practiceId={this.state.currentPractice.id}/>
                <TablePaging page={this.state.page} items={this.props.practices.length}
                                    perPage={this.state.perPage} onPageChange={(e) => this.onPageChange(e)}/>
            </div>
        </div>)
    }

    formatDuration(duration) {
        const totalMinutes = Math.floor(duration / 60);
        let hours = Math.floor(totalMinutes/60)
        let minutes = totalMinutes - hours*60
        return `${hours}h ${minutes}m`
    }

    onPageChange(event)
    {
        event.preventDefault();
        const val = event.target.getAttribute('data-page');
        this.changePage(val);
    }

    changePage(page)
    {
        const vals = {...this.state}
        let newPage;
        if (page === 'last') {
            newPage = Math.ceil(this.props.practices.length / this.state.perPage)
        } else if (page === 'first') {
            newPage = 1
        } else {
            newPage = parseInt(page)
        }
        vals.page = parseInt(newPage)
        this.setState(vals)
    }

    setPractice(practice)
    {
        const state = {...this.state}
        state.currentPractice = {
            id: practice._id ? practice._id.toString() : '',
            date: moment(practice.date).format('YYYY-MM-DD'),
            tags: practice.tags,
            source: practice.source,
            comment: practice.comment,
            duration: practice.duration,
        }
        this.setState(state)
    }

    clearPractice()
    {
        this.setPractice({
            id: '',
            date: moment().format('YYYY-MM-DD'),
            tags: [],
            source: 'Web',
            comment: '',
            duration: 0,
        })
    }

}

export default PracticesTable
