import {Component} from "react";
import getSession from './Session'
import Api from "./Lib/Api";
import Loading from "./Loading";
import {Navigate} from "react-router-dom";
import withRouter from "./Component/withRouter";

class Activate extends Component {
    state = {
        isLoading: true,
        isLoggedIn: false,
        success: false,
    }
    async componentDidMount() {
        const {token} = {...this.props.params}
        const session = getSession()
        const accessToken = await session.getAccessToken()
        if(accessToken) {
            this.setState({isLoggedIn: !!accessToken, isLoading: false})
        } else {
            const response = await Api.get(`/user/activate/${token}`)
            this.setState({
                isLoading: false,
                success: response.success,
            })
        }
    }

    render() {
        if(this.state.isLoading) {
            return <Loading />
        } else {
            return this.state.isLoggedIn ? <Navigate to="/dashboard" replace={true} /> :
            (<div className={'login-page'} >
                  <div className={'login-box'}>
                      <div className={'card'}>
                          <div className={'card-body login-card-body'}>
                              {this.renderMessage()}
                          </div>
                      </div>
                  </div>
                </div>
            )
        }
    }
    renderMessage() {
        return this.state.success ? (<p className={'login-box-msg'}>
            Account activated successefuly
            <br/>
            Please <a href={'/login'}>Login</a>
        </p>) : (
            <p className={'login-box-msg'}>
                Invalid token
            </p>
        )
    }
}

export default withRouter(Activate)