import {Component} from "react";
import Api from "../../../Common/Lib/Api";

export default class AddMatchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            match: {...props.match},
            tags: tagsToString(props.match.tags)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.match.id !== state.match.id) {
            const match = {...props.match}
            const tags = tagsToString(props.match.tags)
            return {
                match,
                tags,
            };
        } else {
            return state
        }
    }

    render() {
        const isEdit = !!this.state.match.id
        return (
            <div className="modal fade" id="match-modal" aria-hidden="true" tabIndex='-1'>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={e => this.handleSubmit(e)}>
                        <div className="modal-header">
                            <h4 className="modal-title">{isEdit ? 'Edit' : 'Add'} Match</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type={"hidden"} name={'id'} value={this.state.match.id}/>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <label>Date</label>
                                    <input name={'date'}
                                           type="date"
                                           onChange={(e) => this.onChange(e)} value={this.state.match.date}
                                           className="form-control"/>
                                </div>
                                <div className={'col-6'}>
                                    <label>Opponent</label>
                                    <input list={'players'} name={'opponent'} onChange={(e) => this.onChange(e)}
                                           value={this.state.match.opponent} className="form-control"/>
                                    <datalist id={'players'}>
                                        {this.props.players.map(player => {
                                            return (<option key={player + this.state.match.id} value={player}/>)
                                        })}
                                    </datalist>
                                </div>
                                <div className={'col-6'}>
                                    <label>Double Partner</label>
                                    <input list={'players'} name={'doublePartner'} onChange={(e) => this.onChange(e)}
                                           value={this.state.match.doublePartner} className="form-control"/>
                                    <datalist id={'players'}>
                                        {this.props.players.map(player => {
                                            return (<option key={player + this.state.match.id} value={player}/>)
                                        })}
                                    </datalist>
                                </div>
                                <div className={'col-6'}>
                                    <label>Double Opponent</label>
                                    <input list={'players'} name={'doubleOpponent'} onChange={(e) => this.onChange(e)}
                                           value={this.state.match.doubleOpponent} className="form-control"/>
                                    <datalist id={'players'}>
                                        {this.props.players.map(player => {
                                            return (<option key={player + this.state.match.id} value={player}/>)
                                        })}
                                    </datalist>
                                </div>
                                <div className={'col-6'}>
                                    <label>Surface</label>
                                    <select className="form-control" name={'surface'}
                                            value={this.state.match.surface || 'hard'}
                                            onChange={(e) => this.onChange(e)}>
                                        <option value="clay">Clay</option>
                                        <option value="hard">Hard</option>
                                        <option value="grass">Grass</option>
                                        <option value="carpet">Carpet</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className={'col-6'}>
                                    <label>Result</label>
                                    <input name={'result'} value={this.state.match.result}
                                           onChange={(e) => this.onChange(e)}
                                           disabled={this.state.match.origin === 'Garmin' ? 'disabled' : ''} type="text"
                                           className="form-control"/>
                                    <span className={'text-muted'}>Example: <b>4/6 7/6(6) 10:5</b>.<br/>Regular sets with "/" separator, tie breaks with ":"</span>
                                </div>
                                <div className={'col-6'}>
                                    <label>Tags</label>
                                    <input name={'tags'} placeholder={'#tournament #important'} value={this.state.tags}
                                           onChange={(e) => this.setTags(e)} type="text" className="form-control"/>
                                </div>
                                {this.renderWinner()}
                                <div className={'col-6'}>
                                    <label>Note</label>
                                    <textarea className={'form-control'} value={this.state.match.comment}
                                              name={'comment'} onChange={(e) => this.onChange(e)} maxLength={200}/>
                                </div>
                            </div>
                        </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" disabled data-dismiss="modal">Close
                                </button>
                                <button type="submit" className="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>)
    }

    renderWinner() {
        const isEdit = !!this.state.match.id
        let select = (<div className={'col-6'}>
        <label>Winner</label>
            <select value={this.state.match.winner} onChange={(e) => this.onChange(e)}  name={'winner'} className="custom-select" >
                    <option value={'auto'}>[ auto ]</option>
                    <option value={1}>me</option>
                    <option value={0}>not finished</option>
                    <option value={-1}>opponent</option>
                </select>
        </div>)
        return isEdit ? select : undefined
    }

    async handleSubmit (e) {
        e.preventDefault()
        const {match} = this.state
        match.tags = tagsToArray(this.state.tags || '')
        if(match.id) {
            await Api.put(`match/${match.id}`, match)
        } else {
            await Api.post('match', match)
        }
        window.location.reload(false);
    }

    onChange(e) {
        const state = {...this.state}
        let value = e.target.value
        if(e.target.name === 'result') {
            value = value.replaceAll(/[^\d():/\s]/g, '')
        }

        if(e.target.name === 'winner') {
            if(!isNaN(parseInt(value))) {
                value = parseInt(value)
            }
        }

        state.match[e.target.name] = value;
        this.setState(state)
    }

    setTags(e) {
        const state = {...this.state}
        state.tags = e.target.value
        this.setState(state)
    }
}

function tagsToString(tags) {
    return tags.filter(tag => !!tag).map(tag => !tag.startsWith('#') ? `#${tag}` : tag).join(' ')
}

function tagsToArray(tags) {
    return tags.split('#').filter(tag => !!tag).map(tag => tag.trim())
}
