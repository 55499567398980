import {Component} from "react";

export default class WarningBar extends Component {
    render() {
        return (
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={`alert alert-${this.props.type || 'info'} alert-dismissible`}>
                        <h5><i className={this.getIcon()}></i>{` ${this.props.header}` || ' Important!'}</h5>
                        <span dangerouslySetInnerHTML={{__html: this.props.text}} />
                    </div>
                </div>
            </div>
        )
    }

    getIcon() {
        switch(this.props.type) {
            case 'warning':
                return 'fas fa-exclamation-triangle'
            case 'danger':
                return 'fas fa-exclamation-circle danger'
            case 'info':
            default:
                return 'fas fa-info-circle'
        }
    }
}