import {Component} from "react";
import moment from "moment/moment";

export default class FiltersSection extends Component {
    state = {
        filters: {
            player: 'all',
            matchType: 'all',
            surface: 'all',
            dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
            dateTo: moment().endOf('year').format('YYYY-MM-DD'),
            doubles: false,
            tags: {
                and: [],
                or: [],
                exluded: []
            },
            showUnfinished: false,
        },
    }

    componentDidMount() {
        this.setState({filters: this.props.defaults})
    }

    render() {
        const players = this.props.matches.map(match => match.opponent).filter((opponent, index, array) => array.indexOf(opponent) === index).sort((p1, p2) => p1.localeCompare(p2))
        const surfaces = this.props.matches.map(match => match.surface).filter((surface, index, array) => array.indexOf(surface) === index)
        const tags = [].concat(...this.props.matches.map(match => match.tags)).filter(tag => !!tag).filter((opponent, index, array) => array.indexOf(opponent) === index)

        const playerOptions = players.map(player => (
            <option key={player} value={player}>{player}</option>))

        const matchesTypeOptions = ['Short', 'Bo3', 'Bo3St', 'Bo5'].map(matchType => {
            return (
            <option key={matchType} defaultValue={this.state.filters.matchType === matchType ? 'selected' : ''}
                    value={matchType}>{matchType}</option>)})
        matchesTypeOptions.unshift((<option key={'all'} value={'all'}>{'All'}</option>))
        const surfacesOptions = surfaces.map(surface => (
            <option key={surface} defaultValue={this.state.filters.surface === surface ? 'selected' : ''}
                    value={surface}>{surface}</option>))
        surfacesOptions.unshift((<option key={'all'} value={'all'}>{'All'}</option>))
        const tagsOptions = tags.map(tag => (
            <option key={tag} defaultValue={this.state.filters.tag === tag ? 'selected' : ''}
                    value={tag}>{tag}</option>))
        return (
            <div className="card">
                <div className="card-body">
                    <form onChange={this.onFilterChange.bind(this) || ((event) => {
                    })}>
                        <div className={'row'}>
                            <div className="col-sm-2">
                                <div className={'row'}>
                                    <label>Opponent</label>
                                    <input list={'players'} name={'player'} className={'form-control'} onChange={() => {
                                    }} value={this.state.filters.player === 'all' ? '' : this.state.filters.player}/>
                                    <datalist id={'players'}>
                                        {playerOptions}
                                    </datalist>
                                </div>
                                <div className={'row'}>
                                    <label></label>
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" name="doubles" className="custom-control-input"
                                               id="doubles" checked={this.state.filters.doubles || false}
                                               onChange={() => {
                                               }}/>
                                        <label className="custom-control-label" htmlFor="doubles">doubles</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-1">
                                <div className={'row'}>
                                    <label>Type</label>
                                    <select name={'matchType'} className="custom-select" onChange={() => {
                                    }} value={this.state.filters.matchType}>
                                        {matchesTypeOptions}
                                    </select>
                                </div>
                                <div className={'row'}>
                                    <label></label>
                                    <div className="custom-control custom-switch">
                                    <input type="checkbox" name="showUnfinished" className="custom-control-input"
                                               id="showUnfinished" checked={this.state.filters.showUnfinished || false} onChange={() => {}}/>
                                        <label className="custom-control-label" htmlFor="showUnfinished">Show
                                            unfinished</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-1">
                                <label>Surface</label>
                                <select name={'surface'} className="custom-select" onChange={() => {}}  value={this.state.filters.surface}>
                                    {surfacesOptions}
                                </select>
                            </div>
                            <div name={'dateFrom'} className={'col-sm-2'}>
                                <label>Date from</label>
                                <input className="form-control" name={'dateFrom'}
                                       defaultValue={this.props.defaults.dateFrom} type={'date'}/>
                            </div>
                            <div name={'dateTo'} className={'col-sm-2'}>
                                <label>Date to</label>
                                <input className="form-control" name={'dateTo'} onChange={() => {}} defaultValue={this.state.filters.dateTo}
                                       type={'date'}/>
                            </div>
                            <div className="col-sm-3">
                                <div className={'row'}>
                                    <div>
                                        <label>Tags</label>
                                        <div className={'input-group'}>
                                            <input list={'tags'} name={'tagFilter'} className={'form-control'}/>
                                            <span className="input-group-append">
                                                <div className={'btn-group'}>
                                                    <button type="button" className="btn btn-info btn-flat" onClick={() => {this.addTag('and')}}>And</button>
                                                    <button type="button" className="btn btn-success btn-flat" onClick={() => {this.addTag('or')}}>Or</button>
                                                    <button type="button" className="btn btn-error btn-danger" onClick={() => {this.addTag('exluded')}}>Not</button>
                                                </div>
                                    </span>
                                        </div>
                                        <datalist id={'tags'}>
                                            {tagsOptions}
                                        </datalist>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div>
                                        {this.state.filters.tags.and.map(tag => (
                                            <span key={`and-${tag}`} onClick={(event) => this.removeTag('and', tag)}
                                                  style={{cursor: 'pointer', margin: '0 2px'}}
                                                  className="badge badge-primary"><span
                                                style={{padding: '0 5px 0 2px'}}>{tag}</span><i
                                                className="fas fa-window-close"></i></span>
                                        ))}
                                        {this.state.filters.tags.or.map(tag => (
                                            <span key={`or-${tag}`} onClick={(event) => this.removeTag('or', tag)}
                                                  style={{cursor: 'pointer', margin: '0 2px'}}
                                                  className="badge badge-success"><span
                                                style={{padding: '0 5px 0 2px'}}>{tag}</span><i
                                                className="fas fa-window-close"></i></span>
                                        ))}
                                        {this.state.filters.tags.exluded.map(tag => (
                                            <span key={`exluded-${tag}`} onClick={(event) => this.removeTag('exluded', tag)}
                                                  style={{cursor: 'pointer', margin: '0 2px'}}
                                                  className="badge badge-danger"><span
                                                style={{padding: '0 5px 0 2px'}}>{tag}</span><i
                                                className="fas fa-window-close"></i></span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-1'}>
                                <div className={'row'}>
                                    <button type={'button'} className="form-control btn btn-info" onClick={() => {this.resetForm()}}>Reset search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>)
    }

    resetForm() {
        const filters = {
            player: 'all',
            matchType: 'all',
            surface: 'all',
            dateFrom: '2023-01-01',
            dateTo: moment().endOf('year').format('YYYY-MM-DD'),
            tags: {
                and: [],
                or: [],
                exluded: []
            },
            showUnfinished: false,
            doubles: false,
        }
        this.setState({filters})
        this.props.onFilterChange(filters)
    }

    removeTag(where, tag) {
        const filters = {...this.state.filters}
        const tags = {...this.state.filters.tags}
        const filteredTags = tags[where].filter(_tag => _tag !== tag)
        filters.tags[where] = filteredTags
        this.setState({filters})
        this.props.onFilterChange(filters)
    }

    addTag(where) {
        const filters = {...this.state.filters}
        const element = document.getElementsByName('tagFilter')[0]
        const tagName = element.value
        const tags = {...this.state.filters.tags}
        if(tagName && !tags[where].includes(tagName)) {
            tags[where].push(tagName)
        }
        element.value = null
        filters.tags = tags
        this.setState({filters})
        this.props.onFilterChange(filters)
    }

    onFilterChange(event) {
        const filters = {...this.state.filters}
        const filterName = event.target.name
        if(['tagFilter'].includes(filterName)) {
           return
        }
        let filterValue;
        if (!event.target.multiple) {
            filterValue = event.target.value
        } else {
            const options = event.target.options;
            filterValue = [];
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    filterValue.push(options[i].value);
                }
            }
        }
        if (event.target.type === 'checkbox') {
            filterValue = !!event.target.checked;
        }
        filters[filterName] = filterValue

        this.setState({filters})
        this.props.onFilterChange(filters)
    }
}