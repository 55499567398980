import {Component} from "react";

class BreakerSvg extends Component {
    render() {
        return (<svg
            className={this.props.className}
            style={this.props.style}
            key={this.props.uniqKey}
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 36 36'}
        >
            <path style={{fill:'#F4900C'}}
                  d="M35.22 30.741l-.024.024c-.97.97-2.542.97-3.511 0L7.835 6.915a1.49 1.49 0 0 1 0-2.107l1.429-1.429a1.49 1.49 0 0 1 2.107 0l23.85 23.85c.969.97.969 2.542-.001 3.512z"></path>
            <path style={{fill:'#66757F'}}
                  d="M17.765 6.946L14.229 3.41a1.5 1.5 0 0 0-2.121 0L8.573 6.946c-2.128 2.092-3.85 3.015-6.055 3.056c-.171 1.573.665 5.193 1.967 6.652c1.692 1.896 4.545 2.495 7.223 2.454c-.134-2.363.437-4.422 2.521-6.506l3.535-3.536a1.497 1.497 0 0 0 .001-2.12z"></path>
            <path style={{fill:'#CCD6DD'}}
                  d="M2.518 10.002C1.767 10.016.962 9.93.064 9.75c-.707 4.95 7.071 12.728 12.021 12.021c-.193-.937-.328-1.819-.376-2.663c-4.418-1.409-8.107-5.072-9.191-9.106z"></path>
        </svg>)

    }
}

export default BreakerSvg