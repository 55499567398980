import {Component} from "react";

export default class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block">
                    Powered by: <b><a href={'https://allmyrackets.com'}>allmyrackets.com</a></b>
                </div>
                <strong>Beta version</strong>
            </footer>
            )

    }
}