import Session from "../Session";
import config from "../Config/config"

export default class Api {
    static async post(path, body, options) {
        return Api.doRequest('POST', path, body, options)
    }

    static async put(path, body, options) {
        return Api.doRequest('PUT', path, body, options)
    }

    static async patch(path, body, options) {
        return Api.doRequest('PATCH', path, body, options)
    }

    static async get(path, options) {
        return Api.doRequest('GET', path, undefined, options)
    }

    static async delete(path, options) {
        return Api.doRequest('DELETE', path, undefined, options)
    }

    static async doRequest(method, path, body, options) {
        const session = Session()
        const token = options?.token ?? await session.getAccessToken()
        const headers = {}

        if(token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        const init = {
            method,
            headers
        }

        if(body) {
            headers['Content-Type'] = 'application/json'
            init.body = JSON.stringify(body)
        }

        const trimmedPath = path.replace(/^\/+/, '').replace(/\/+$/, '');

        const result = await fetch(`${config.baseUrl}/${trimmedPath}`, init)

        if(result.status === 401) {
            Session().logout()
            window.location.reload(false);
        }

        if(result.status === 500) {
            throw new Error(result.message);
        }

        return result.json()
    }
}