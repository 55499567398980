import {Component} from "react";

class LostBreakOpportunitySvg extends Component {
    render() {
        const racketColor = '#024ab1'
        const ballColor1 = '#555555'
        const ballColor2 = '#aaaaaa'
        return (<svg
            className={this.props.className}
            style={this.props.style}
            key={this.props.uniqKey}
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 128 128'}
        >
            <path
                d="M32.26 94.85s5.66-8.06 5.87-8.38s8.69-19.38 8.69-19.38l3.77 1.47l-.7 1.58s-.93.66-1.77 2.55s-5.57 14.46-5.57 14.46l19.24-6.23l23.04-3.35s11.42-6.49 13.51-7.75c2.09-1.26 14.03-14.35 14.03-14.35l6.18-12.36l1.36-13.93L118 16.67s7.99 7.35 5.43 23.28c-3.24 20.14-18.01 36.59-41.42 42.48c-10.95 2.76-24.08 3.65-35.38 8.3c-5.86 2.41-12.87 10.19-12.87 10.19l-1.5-6.07z"
                fill={racketColor}></path>
            <path
                d="M51.2 40.7l-2.13 14.68l-1.67 10.67l11.55 4.71s-8.13-13.63-3.36-26.75c5.97-16.41 22.18-30.55 35.47-31.53c9.19-.68 11.67 1.38 16.23 3.58c5.91 2.84 9.31 9.6 9.31 9.6s-1.34-8.91-1.67-9.32s-14.77-8.69-14.77-8.69s-15.33.4-15.74.65s-13.37 6.32-13.62 6.97c-.25.66-12.39 13.53-13.7 15.42c-1.31 1.89-5.9 10.01-5.9 10.01z"
                fill={racketColor}></path>
            <path
                d="M105.68 64.52c-.38 0-.76-.14-1.05-.43L66.02 26.45a1.5 1.5 0 0 1-.03-2.12c.58-.59 1.53-.6 2.12-.03l38.61 37.65c.59.58.61 1.53.03 2.12c-.29.3-.68.45-1.07.45z"
                fill="#b7cdd2"></path>
            <path
                d="M111.78 57.36c-.38 0-.77-.15-1.06-.44L73.17 19.48c-.59-.58-.59-1.53 0-2.12s1.53-.59 2.12 0l37.54 37.44c.59.58.59 1.53 0 2.12c-.28.29-.67.44-1.05.44z"
                fill="#b7cdd2"></path>
            <path
                d="M115.84 48.48c-.39 0-.78-.15-1.07-.45L82.25 14.65c-.58-.59-.57-1.54.03-2.12c.59-.58 1.54-.57 2.12.03l32.52 33.37c.58.59.57 1.54-.03 2.12c-.29.29-.67.43-1.05.43z"
                fill="#b7cdd2"></path>
            <path
                d="M118.09 36.93c-.38 0-.76-.14-1.05-.43L93.18 12.96a1.49 1.49 0 0 1-.01-2.12c.58-.59 1.53-.6 2.12-.01l23.85 23.53c.59.58.6 1.53.01 2.12c-.29.3-.67.45-1.06.45z"
                fill="#b7cdd2"></path>
            <path
                d="M98.45 71.4c-.38 0-.76-.14-1.05-.43L59.81 34.14a1.5 1.5 0 0 1 2.1-2.14L99.5 68.83a1.5 1.5 0 0 1-1.05 2.57z"
                fill="#b7cdd2"></path>
            <path
                d="M88.46 76.5c-.39 0-.78-.15-1.07-.45l-32.2-32.84a1.5 1.5 0 0 1 2.14-2.1l32.2 32.84a1.5 1.5 0 0 1-1.07 2.55z"
                fill="#b7cdd2"></path>
            <path
                d="M78.19 78.86c-.38 0-.75-.14-1.05-.43L52.65 54.58a1.5 1.5 0 0 1 2.09-2.15l24.5 23.85c.59.58.61 1.53.03 2.12c-.3.31-.69.46-1.08.46z"
                fill="#b7cdd2"></path>
            <path
                d="M56.58 67.09a1.5 1.5 0 0 1-1.05-2.57l50.17-49.31a1.5 1.5 0 0 1 2.1 2.14L57.63 66.66c-.29.29-.67.43-1.05.43z"
                fill="#b7cdd2"></path>
            <path
                d="M54.02 58.21c-.38 0-.75-.14-1.04-.42c-.6-.58-.61-1.53-.03-2.12c16.57-17.11 41.58-43.03 42.94-44.72a1.498 1.498 0 0 1 2.59 1.5c-.13.26-.53 1.06-43.37 45.3c-.31.31-.7.46-1.09.46z"
                fill="#b7cdd2"></path>
            <path
                d="M56.26 43.56a1.5 1.5 0 0 1-1.09-2.53l27-28.5c.57-.6 1.61-.57 2.21 0c.6.57.6 1.4.03 2L57.35 43.09c-.29.31-.69.47-1.09.47z"
                fill="#b7cdd2"></path>
            <path
                d="M61.5 75.65a1.5 1.5 0 0 1-1.07-2.55l51.43-52.76c.58-.59 1.66-.76 2.25-.18c.59.58.47 1.69-.11 2.28L62.58 75.2c-.3.3-.69.45-1.08.45z"
                fill="#b7cdd2"></path>
            <path
                d="M71.34 79.39c-.38 0-.76-.14-1.05-.43a1.49 1.49 0 0 1-.01-2.12l47.28-47.92a1.5 1.5 0 0 1 2.12-.01c.59.58.6 1.53.01 2.12L72.41 78.95c-.29.29-.68.44-1.07.44z"
                fill="#b7cdd2"></path>
            <path
                d="M85.15 77.88c-.35 0-.7-.12-.98-.36c-.62-.54-.7-1.46-.18-2.08c.54-.64 24.77-24.88 32.18-32.3a1.49 1.49 0 0 1 2.12 0c.59.59.59 1.54 0 2.12c-8.64 8.64-31.23 31.25-32.02 32.12c-.3.33-.71.5-1.12.5z"
                fill="#b7cdd2"></path>
            <path
                d="M40.09 86.67s5.86-16.62 7.45-17.15c.75-.25 1.68 1.75 4.6 4.6c3.18 3.1 5.02 4.18 4.77 5.44c-.4 2-16.82 7.11-16.82 7.11zm77.48-52.95c-.69 16.58-13.25 34.16-29.97 40.59c-10.32 3.97-22.33 3.08-29.38-4.37c-6.81-7.19-7.63-14.55-5.86-23.03c3.08-14.69 16.57-30.97 28.98-35.33s20.8-2.3 28.31 3.3c5.85 4.38 8.19 12.39 7.92 18.84zm.13-17.49c-3.97-4.74-12.64-13.46-29.4-11.01c-15.11 2.21-27.62 14.26-34.96 27.13c-5.84 10.26-6.42 18.34-8.16 26.72c-1.69 8.13-7.94 23.68-9.25 26.28c-3.17 6.27-6.31 7.31-6.73 8.22l2.42 7.82s2.14-3.83 7.22-8.9c5.07-5.07 16.49-7.53 21.35-8.52c5.56-1.14 14.39-1.07 28.73-6.26c16.53-5.98 32.15-25.28 32.81-41.83c.38-9.76.08-14.74-4.03-19.65z"
                fill="#1f87ff"></path>
            <path
                d="M30.34 91.48c-1.49.25-16.69 16.65-16.69 16.65s-5.73 2.82-6.8 3.56s-1.18 2.67-.11 3.85s7.49 7.49 8.34 8.24s3.18.7 3.74-.96c.48-1.41 2.72-5.38 2.72-5.38s16.65-17.24 16.65-18.1s-7.21-7.96-7.85-7.86z"
                fill="#5f6369"></path>
            <path
                d="M11.77 113.08c.52 2.76 4.24 2.79 6.4 1.24c1.66-1.2.85-2.77 2.01-4.01c1.38-1.48 2.65-.64 3.93-2.13c1.28-1.49.85-2.48 1.66-3.41c1.15-1.32 2.35-.47 3.54-1.88c1.2-1.41.51-2.56 1.41-3.46s2.99-.47 2.48-2.77c-.51-2.3-3.63-2.01-5.04-.9c-1.11.88-1.11 2.39-2.26 3.41c-1.02.9-2.18.6-3.54 1.84c-1.37 1.24-.98 2.82-1.92 3.84s-2.22.26-3.67 1.88c-1.51 1.69-.73 2.6-2.18 3.67c-.98.71-3.25.42-2.82 2.68z"
                fill="#858686"></path>
            <path
                d="M12.53 9.35L4.12 22.87s0 10 9.09 15.11c9.35 5.26 21.84 1.55 26.36-7.84c4.43-9.2.23-20-4.32-23.52c-5.15-3.99-22.72 2.73-22.72 2.73z"
                fill={ballColor1}></path>
            <path
                d="M15.12 3.36C8.33 6.6 4.37 12.68 3.8 18.81c-.19 2.07 0 3.88 0 3.88s2.8 9.69 15.13 10.5c13.26.88 19.43-7.38 19.82-16.44c.31-7.19-3.69-10.5-3.69-10.5s-1.81-2.44-5.82-3.69c-1.71-.55-7.68-2.26-14.12.8z"
                fill={ballColor2}></path>
            <path
                d="M7.55 18.93c4.39 5.27 12.14 9.85 13.51 14.94c1.19 4.44-.69 6.5-.69 6.5s.56.31 1.19.38c.63.06 1.31-.25 1.31-.25s1.38-1.69.88-6.5c-.5-4.81-5.13-8.63-8.25-11.07c-2.59-2.02-5.68-4.48-6.82-6.44C6.87 13.37 6.93 9.8 6.93 9.8s-.88.31-1.31 1.56c-.42 1.18-.88 4.2 1.93 7.57z"
                fill="#ffffff"></path>
            <path
                d="M26.44 1.67s.56 1.25 2.94 4c2.23 2.58 5.53 6.21 7.82 10.38c1.75 3.19 3.69 10.69 3.69 10.69s.75-2.63.75-4.38c0-.94-1.56-5.19-2.94-7.82c-1.62-3.1-4.75-6.75-6.07-8.44c-.81-1.05-3.25-3.94-3.25-3.94s-2.82-.8-2.94-.49z"
                fill="#ffffff"></path>
        </svg>)

    }
}

export default LostBreakOpportunitySvg