import {Component} from "react";
import getSession from './Session'
import Api from "./Lib/Api";
import Loading from "./Loading";
import {Navigate} from "react-router-dom";

async function loginUser(credentials) {
    const data = await Api.post('/auth/login', credentials, {token: null})
    return data.accessToken ? data : null
}

class Login extends Component {
    formData = {
        email: '',
        password: '',
        remeberMe: false,
    }

    state = {
        isLoading: true,
        isLoggedIn: false,
    }
    async componentDidMount() {
        const session = getSession()
        const accessToken = await session.getAccessToken()
        this.setState({isLoggedIn: !!accessToken, isLoading: false})
    }

    render() {
        const
            handleSubmit = async e => {
            const email = this.formData.email
            const password = this.formData.password
            const rememberMe = this.formData.remeberMe
            this.setState({
                generalInfoMessage: '',
                generalErrorMessage: '',
                isLoading: true
            })
            e.preventDefault();
            const data = await loginUser({
                email,
                password
            });
            this.setState({isLoading: false})
            if(data) {
                getSession().setAuthData({...data, rememberMe })
                window.location.reload(false);
            }
        }

        if(this.state.isLoading) {
            return <Loading />
        } else {
            return this.state.isLoggedIn ? <Navigate to="/dashboard" replace={true} />
                : (<div className="hold-transition login-page">
                    <div className="login-box">
                        <div className="card card-outline card-primary">
                            <div className="card-body">
                                <p className="login-box-msg">Sign in to start your session</p>
                                <span id={'generalInfoMessage'} className="text-success">{this.state.generalInfoMessage}</span>
                                <span id={'generalInfoMessage'} className="text-danger">{this.state.generalErrorMessage}</span>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" placeholder="Email" onChange={e => this.formData.email = e.target.value}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Password" onChange={e => this.formData.password = e.target.value}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" onChange={e => this.formData.remeberMe = e.target.checked}/>
                                                <label htmlFor="remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                        </div>
                                    </div>
                                </form>

                                <p className="mb-1">
                                    <a href="/user/reset-password-request">I forgot my password</a>
                                </p>
                                <p className="mb-0">
                                    <a href="/register" className="text-center">Register</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }
}

export default Login