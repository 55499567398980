import {Component} from "react";

class SharpShooterSvg extends Component {
    render() {
        return (<svg
            className={this.props.className}
            style={this.props.style}
            key={this.props.uniqKey}
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 512 512'}
        >
            <circle style={{fill: '#ED5564'}} cx="255.996" cy="255.996" r="10.664"/>
            <g>
                <path style={{fill: '#FC6E51'}} d="M255.996,479.992c-59.835,0-116.084-23.297-158.388-65.607C55.296,372.088,32,315.84,32,255.996
		c0-59.835,23.296-116.084,65.608-158.388C139.912,55.296,196.161,32,255.996,32c59.844,0,116.092,23.296,158.389,65.608
		c42.295,42.304,65.607,98.553,65.607,158.388c0,59.843-23.312,116.091-65.607,158.388
		C372.088,456.695,315.84,479.992,255.996,479.992z M255.996,53.336c-54.132,0-105.029,21.078-143.302,59.358
		c-38.281,38.273-59.359,89.171-59.359,143.302c0,54.125,21.078,105.029,59.358,143.31c38.273,38.281,89.17,59.342,143.302,59.342
		c54.125,0,105.029-21.061,143.311-59.342c38.279-38.281,59.342-89.186,59.342-143.31c0-54.132-21.062-105.029-59.342-143.303
		C361.025,74.413,310.121,53.336,255.996,53.336z"/>
                <path style={{fill: '#FC6E51'}} d="M255.996,437.338c-48.437,0-93.975-18.875-128.217-53.125
		c-34.249-34.25-53.116-79.779-53.116-128.217s18.867-93.975,53.116-128.217c34.242-34.248,79.78-53.116,128.217-53.116
		c48.438,0,93.967,18.867,128.217,53.116c34.25,34.242,53.123,79.78,53.123,128.217s-18.873,93.966-53.123,128.216
		S304.434,437.338,255.996,437.338z M255.996,95.999c-42.734,0-82.913,16.64-113.139,46.859
		c-30.218,30.225-46.858,70.405-46.858,113.138s16.64,82.904,46.858,113.138c30.226,30.219,70.405,46.859,113.139,46.859
		s82.904-16.641,113.123-46.859c30.234-30.234,46.875-70.404,46.875-113.138s-16.641-82.913-46.875-113.139
		C338.9,112.639,298.73,95.999,255.996,95.999z"/>
            </g>
            <path style={{fill: '#DA4453'}} d="M255.996,319.996c-35.289,0-63.999-28.719-63.999-64c0-35.289,28.71-63.999,63.999-63.999
	c35.281,0,63.998,28.71,63.998,63.999C319.994,291.277,291.277,319.996,255.996,319.996z M255.996,213.333
	c-23.523,0-42.664,19.14-42.664,42.664c0,23.531,19.141,42.656,42.664,42.656c23.531,0,42.656-19.125,42.656-42.656
	C298.652,232.473,279.527,213.333,255.996,213.333z"/>
            <g>
                <path style={{fill: '#434A54'}} d="M501.336,266.66H394.65c-5.875,0-10.656-4.773-10.656-10.664c0-5.891,4.781-10.664,10.656-10.664
		h106.686c5.875,0,10.656,4.773,10.656,10.664C511.992,261.887,507.211,266.66,501.336,266.66z"/>
                <path style={{fill: '#434A54'}} d="M117.334,266.66H10.664C4.773,266.66,0,261.887,0,255.996c0-5.891,4.773-10.664,10.664-10.664
		h106.67c5.891,0,10.664,4.773,10.664,10.664C127.998,261.887,123.225,266.66,117.334,266.66z"/>
                <path style={{fill: '#434A54'}} d="M255.996,511.992c-5.891,0-10.664-4.781-10.664-10.656V394.65c0-5.875,4.773-10.656,10.664-10.656
		c5.891,0,10.664,4.781,10.664,10.656v106.686C266.66,507.211,261.887,511.992,255.996,511.992z"/>
                <path style={{fill: '#434A54'}} d="M255.996,127.998c-5.891,0-10.664-4.773-10.664-10.664V10.664C245.332,4.773,250.105,0,255.996,0
		c5.891,0,10.664,4.773,10.664,10.664v106.67C266.66,123.225,261.887,127.998,255.996,127.998z"/>
            </g>
        </svg>)

    }
}

export default SharpShooterSvg