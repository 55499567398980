import {Component} from "react";
import MatchesTablePaging from "./MatchesTablePaging";

export default class ByOpponentTable extends Component {
    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
    }

    state = {
        page: 1,
        perPage: 5
    }
    render() {
        const offset = (this.state.page * this.state.perPage) - this.state.perPage
        const limit = offset + this.state.perPage
        const byPlayer = Object.values(this.props.matches.reduce((matches, match) => {
            if(!matches[match.opponent]) {
                matches[match.opponent] = {
                    opponent: match.opponent,
                    matches: 0,
                    matchesWon: 0,
                    matchesLost: 0,
                    setsWon: 0,
                    setsLost: 0,
                    gamesWon: 0,
                    gamesLost: 0,
                    tieBreaksWon: 0,
                    tieBreaksLost: 0
                }
            }
            matches[match.opponent].matches += 1
            matches[match.opponent].matchesWon += match.matchResult === 1 ? 1 : 0
            matches[match.opponent].matchesLost += match.matchResult === -1 ? 1 : 0
            matches[match.opponent].setsWon += match.details.setsWon
            matches[match.opponent].setsLost += match.details.setsLost
            matches[match.opponent].gamesWon += match.details.gamesWon
            matches[match.opponent].gamesLost += match.details.gamesLost
            matches[match.opponent].tieBreaksWon += match.details.tieBreaksWon
            matches[match.opponent].tieBreaksLost += match.details.tieBreaksLost
            return matches
        }, {}))
        byPlayer.sort((match1, match2) => match1.matches > match2.matches ? -1 :1)

        return (<div className="card">
            <div className={'card-header'}>
                <h3 className="card-title">{this.props.title}</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                </div>
            </div>
        <div className="card-body table-responsive p-0">
            <table className="table">
                <thead>
                <tr>
                    <th>Opponent</th>
                    <th>Matches</th>
                    <th>Matches Won</th>
                    <th>Matches Lost</th>
                    <th>Sets Won</th>
                    <th>Sets Lost</th>
                    <th>Games Won</th>
                    <th>Games Lost</th>
                    <th>TieBreaks Won</th>
                    <th>TieBreaks Lost</th>
                </tr>
                </thead>
                <tbody>

                {byPlayer.map(row => {
                    return (<tr key={row.opponent}>
                        <td>{row.opponent}</td>
                        <td>{row.matches}</td>
                        <td>{row.matchesWon}</td>
                        <td>{row.matchesLost}</td>
                        <td>{row.setsWon}</td>
                        <td>{row.setsLost}</td>
                        <td>{row.gamesWon}</td>
                        <td>{row.gamesLost}</td>
                        <td>{row.tieBreaksWon}</td>
                        <td>{row.tieBreaksLost}</td>
                    </tr>
                )}).slice(offset, limit)}
                </tbody>
            </table>
        </div>
            <div className="card-footer clearfix">
                <MatchesTablePaging page={this.state.page} items={byPlayer.length} perPage={this.state.perPage} onPageChange={this.onPageChange} />
            </div>
    </div>)}
    onPageChange(event) {
        event.preventDefault()
        const val = event.target.getAttribute('data-page')
        this.changePage(val)
    }

    changePage(page) {
        const vals = {...this.state}
        let newPage;
        if(page === 'last') {
            newPage = Math.ceil(this.props.matches.length / this.state.perPage)
        }else if(page === 'first') {
            newPage = 1
        } else {
            newPage = parseInt(page)
        }
        vals.page = parseInt(newPage)
        this.setState(vals)
    }
        }
