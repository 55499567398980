import {Component} from "react";
import {fromVersionChecker} from "../../../Common/Lib/versionCheck";

export default class StatsRowHeader extends Component{
    render() {
        const fromVersion = fromVersionChecker(this.props.currentVersion)
        const content = (<tr>
            <td></td>
            <td colSpan={3} align={"center"}><strong>{this.props.header}</strong></td>
            <td></td>
        </tr>)
        return fromVersion(this.props.fromVersion) ? content : null
    }
}