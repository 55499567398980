import {Component} from "react";
import Api from "../../../Common/Lib/Api";
import {refreshConfiguration} from "../../../Common/Storage";

export default class Devices extends Component {
    constructor() {
        super();
        this.deactivateDevice.bind(this)
        this.activateDevice.bind(this)
        this.removeDevice.bind(this)
    }
    state = {
        isLoading: false,
        devices: [],
        currentDeviceId: null,
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <div className="card card-primary">
                <div className={'card-header'}>
                    <h3 className={'card-title'}>Devices</h3>
                </div>
                <div className={'card-body table-responsive'}>
                        <table className={'table'}>
                            <tbody>
                            {this.renderDevices()}
                            </tbody>
                        </table>
                </div>
                <div className={'card-footer clearfix'}>
                    <button className={'btn btn-primary'} onClick={(e) => {e.preventDefault(); this.loadData()}}>Refresh</button>
                </div>
                {this.renderOverlay()}
                <div className="modal fade" id="remove-device-modal" aria-hidden="true" tabIndex='-1'>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Match</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure?
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={(e) => this.removeDevice(e)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderDevices() {
        return this.state.devices.map(device => (
            <tr key={device.id}>
                <td align={'center'}>
                    <img alt="circle" style={{maxHeight: '80px', maxWidth: '80px'}} className={'img-circle img-fluid'}
                         src={device.imageUrl}/>
                </td>
                <td style={{verticalAlign: 'middle'}}>
                    <span className={'badge badge-info'} style={{padding: '5px'}}>{device.appName}</span>
                </td>
                <td style={{verticalAlign: 'middle'}}>{device.displayName}</td>
                <td style={{verticalAlign: 'middle'}}>
                    <span className={`badge badge-${device.isConnected === true ? 'success' : 'danger'}`}
                          style={{
                              padding: '5px',
                              margin: '0 5px 0 0'
                          }}>{device.isConnected === true ? 'connected' : 'not connected'}</span>
                </td>
                <td style={{verticalAlign: 'middle'}}>
                    <a className={`badge badge-info`} href={"#1"}
                       style={{margin: '0 5px 0 0', padding: '5px'}} onClick={(e) => {
                        e.preventDefault();
                        device.isConnected ? this.deactivateDevice(device.id) : this.activateDevice(device.id)
                    }}>{device.isConnected === true ? 'unlink' : 'connect'}</a>
                    <a className="badge badge-danger" style={{margin: '0 5px 0 0', padding: '5px'}} href={'#1'}
                       data-toggle={'modal'} data-target={'#remove-device-modal'} onClick={(e) => {
                        this.setState({currentDeviceId: device.id})
                    }}>remove</a>
                </td>
            </tr>
        ))
    }

    async loadData() {
        this.setState({
            isLoading: true
        })

        const result = await Api.get('user/devices')
        const devices = result.devices.sort(device => device.isConnected ? -1 : 1)
        this.setState({
            isLoading: false,
            devices
        })
    }

    renderOverlay() {
        return this.state.isLoading ? (<div className="overlay dark"><i className="fas fa-2x fa-sync-alt"></i></div>) : ''
    }

    async deactivateDevice(deviceId) {
        await Api.post(`user/deactivateDevice/${deviceId}`, {})
        await refreshConfiguration()
        await this.loadData();
    }

    async activateDevice(deviceId) {
        await Api.post(`user/activateDevice/${deviceId}`, {})
        await refreshConfiguration()
        await this.loadData();
    }

    async removeDevice (e) {
        e.preventDefault()
        const {currentDeviceId} = this.state
        await Api.delete(`user/removeDevice/${currentDeviceId}`)
        await refreshConfiguration()
        await this.loadData();
    }
}