import TennisBallSvg from "../TennisBallSvg";
import PureWinSvg from "./Icons/PureWinSvg";
import SurvivorSvg from "./Icons/SurvivorSvg";
import SharpShooterSvg from "./Icons/SharpShooterSvg";
import LostBreakOpportunitySvg from "./Icons/LostBreakOpportunitySvg";
import BadgeElement from "./BadgeElement";
import {GAME_BADGES, MATCH_BADGES} from "./Badges";
import GamerSvg from "./Icons/GamerSvg";
import BreakerSvg from "./Icons/BreakerSvg";
import BagelSvg from "./Icons/BagelSvg";
import DoubleBagelSvg from "./Icons/DoubleBagelSvg";

export function createBadge(name, {count = undefined, additionalClass = undefined, nameAsTooltip = false, showName = false} = {}) {
    const configuration = getConfiguration(name)
    return configuration ? <BadgeElement tooltip={configuration.tooltip}
                                         nameAsTooltip={nameAsTooltip}
                                         name={configuration.name}
                                         element={configuration.element}
                                         count={count}
                                         additionalClass={additionalClass}
                                         showName={showName}
    /> : undefined
}

function getConfiguration(badgeName) {
    switch (badgeName) {
        case GAME_BADGES.PURE_WIN:
            return {
                element: <PureWinSvg/>,
                name: 'Pure Win',
                tooltip: 'Won game to 0'
            }

        case GAME_BADGES.SURVIVOR:
            return {
                element: <SurvivorSvg/>,
                name: 'Survivor',
                tooltip: 'Won game after losing 0:40'
            }
        case GAME_BADGES.SHARP_SHOOTER:
            return {
                element: <SharpShooterSvg/>,
                name: 'Sharp Shooter',
                tooltip: 'Won game only with aces or dirty aces'
            }
        case GAME_BADGES.LOST_BREAK_OPPORTUNITY:
            return {
                element: <LostBreakOpportunitySvg/>,
                name: 'Lost Break Opportunity',
                tooltip: 'Had an opportunity to break, but finally lost'
            }
        case GAME_BADGES.GAME:
            return {
                element: <TennisBallSvg style={{padding: '5px'}}/>,
                name: 'Game!',
                tooltip: 'Won games as a server'
            }
        case GAME_BADGES.BREAK:
            return {
                element: <TennisBallSvg ballColor={'#d76e15'} style={{padding: '5px'}}/>,
                name: 'Break!',
                tooltip: 'Breaks won'
            }
        case MATCH_BADGES.GAMER: {
            return {
                element: <GamerSvg/>,
                name: 'Game holder',
                tooltip: 'Won all serve games in match'
            }
        }
        case MATCH_BADGES.BREAKER: {
                return {
                    element: <BreakerSvg />,
                    name: 'Breaker',
                    tooltip: `Broke all opponent's games in match`
                }
        }
            case MATCH_BADGES.BAGEL: {
                    return {
                        element: <BagelSvg />,
                        name: 'Bagel',
                        tooltip: 'Won set to 0'
                    }
            }
                case MATCH_BADGES.DOUBLE_BAGEL: {
                        return {
                            element: <DoubleBagelSvg />,
                            name: 'Double Bagel',
                            tooltip: 'Won the match 6/0 6/0'
                        }
                }
        default:
            return undefined
    }
}