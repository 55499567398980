import {Component} from "react";

class BadgeElement extends Component {
    render() {
        return (<div className={`amr-tooltip amr-point-by-point-badge ${this.props.additionalClass || ''}`}>
            {this.props.element}
            <span className={'amr-tooltiptext bottom'}>{this.props.nameAsTooltip ? this.props.name : this.props.tooltip}</span>
            {this.renderCount()}
            {this.renderLabel()}
        </div>)

    }

    renderCount() {
        return this.props.count !== undefined ? (<span
            className={'badge badge-success amr-count'}>{`${this.props.count}x`}</span>) : undefined
    }

    renderLabel() {
        return this.props.showName === true ? (<p className={'bg-primary'} style={{
            padding: '0.25em 0.4em',
            textAlign: 'center',
            borderRadius: '0.4rem',
            whiteSpace: 'nowrap',
            textDecoration: 'none'
        }}>{this.props.name}</p>) : undefined
    }
}

export default BadgeElement