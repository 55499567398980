export const fromVersionChecker = (currentVersion) => (v) => compareVersion(currentVersion, v) > -1
export const compareVersion = (v1, v2) => {
    const version1Arr = v1.split('.')
    const version2Arr = v2.split('.')
    version1Arr.push(...[0,0,0])
    version2Arr.push(...[0,0,0])
    const version1 = version1Arr.slice(0,3)
    const version2 = version2Arr.slice(0,3)

    for(let i = 0;i<version1.length;i++) {
        const v1value = parseInt(version1[i], 10)
        const v2value = parseInt(version2[i], 10)
        if(v1value !== v2value) {
            return v1value > v2value ? 1 : -1
        }
    }
    return 0
}