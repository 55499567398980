import {Component} from "react";
import {createBadge} from "../../../Common/Badges/BadgeFactory";
import TennisBallSvg from "../../../Common/TennisBallSvg";


export default class PointByPointTab extends Component {
    constructor(props) {
        super(props);
        this.allBadges = props.match.pointByPoint.sets.reduce((badges, set) => {
            set.games.forEach(game => {
                Object.entries(game.badges).forEach(([player, gameBadges]) => {
                    gameBadges.forEach(gameBadge => {
                        if(!badges[player][gameBadge]) {
                            badges[player][gameBadge] = 0
                        }
                        badges[player][gameBadge]++
                    })
                })
            })
            return badges;
        }, {1: {}, 2: {}})
    }

    render() {
        return (
            <div className={'card-body'}>
                <div className={'row'} >
                    <div className={'col'} style={{textAlign: 'right'}}>
                        {this.renderBadges(this.allBadges[1])}
                    </div>
                    <div style={{flex: '0 0 24%', maxWidth: '24%'}}>

                    </div>
                    <div className={'col'} style={{textAlign: 'left'}}>
                        {this.renderBadges(this.allBadges[2])}
                    </div>
                </div>
                <div className={'row'} >
                    <div className={'col-12'}>
                        {this.props.match.pointByPoint.sets.map((set, index) => {
                            return (
                                <div key={`set${index}`}>
                                    <p style={{textAlign: 'center', fontSize: '2.3rem'}}>
                                        <span>Set {index + 1}</span>
                                    </p>
                                    {set.games.map(game => this.renderSet(game))}
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        )
    }

    renderBadges(badges) {
        const intBadges = Object.entries(badges)
        intBadges.sort((badge1, badge2) => {
            const indexes = ['game', 'break', 'pureWin', 'survivor', 'sharpShooter', 'lostBreakOpportunity']
            return indexes.indexOf(badge1[0]) < indexes.indexOf(badge2[0]) ? -1 : 1
        })
        return intBadges.map(([badge, count]) => {
            return (createBadge(badge, {count}))
        })
    }

    renderPoint(point, key) {
        return (
            <span key={`${point.player}-${key}`} className={`badge badge-${point.player === 1 ? 'success' : 'danger'}`}
                  style={{margin: '0 2px'}}> {point.result.join(':')} {point.point !== 'SIMPLE' ? `[${point.point}]`: ''}
            </span>
        )
    }

    renderServer(set) {
        return (
            <TennisBallSvg key={`${set.result[0]}-${set.result[1]}+${Math.random()}`} style={{width: '30px'}} ballColor={set.isBreak ? "#d76e15" : undefined}/>
        )
    }

    renderSet(set) {
        return (
            <div key={`set${set.result[0]}${set.result[1]}`} className={'row'}>
                <div className={'col-12'}>
                    <div className={'row'}>
                        <div className={'col-lg-4 col-sm-2'}></div>
                        <div className={'col-lg-4 col-sm-8'} style={{textAlign: 'center'}}>
                            <span>
                                {set.points.map((point, key) => this.renderPoint(point, key))}
                            </span>
                        </div>
                        <div className={'col-lg-4 col-sm-2'}></div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'} style={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignSelf: 'center'
                        }}>
                            <div style={{margin: 'auto 0px', minWidth: '200px', textAlign: 'right'}}>
                                {!set.isTieBreak && set.server === 1 ? (
                                    createBadge(set.isBreak ? 'break' : 'game', {additionalClass: 'min', nameAsTooltip: true})) : undefined}
                                {set.badges[1].filter(badge => !['game', 'break'].includes(badge)).map(badge => {
                                    return (createBadge(badge, {additionalClass: 'min'}))
                                })}
                            </div>
                            <span style={{fontSize: '3.3rem', minWidth: '130px'}}><span style={{padding: '3px'}}>{set.result[0]}</span> / <span style={{padding: '3px'}}>{set.result[1]}</span></span>
                            <div style={{margin: 'auto 0px', minWidth: '200px', textAlign: 'left'}}>
                                {!set.isTieBreak && set.server === 2 ? (
                                    createBadge(set.isBreak ? 'break' : 'game', {additionalClass: 'min', nameAsTooltip: true})) : undefined}
                                {set.badges[2].filter(badge => !['game', 'break'].includes(badge)).map(badge => {
                                    return (createBadge(badge, {additionalClass: 'min'}))
                                })}
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>

        )
    }
}