import jwtDecode from "jwt-decode";
import Api from "./Lib/Api";

let session = null;
const ACCESS_TOKEN_NAME = 'accessToken'
const REFRESH_TOKEN_NAME = 'refreshToken'
const REMEMBER_ME = 'rememberMe'
class Session {
    setAuthData(data) {
        if(data.accessToken) {
            localStorage.setItem(ACCESS_TOKEN_NAME, data[ACCESS_TOKEN_NAME])
        }
        if(data.refreshToken) {
            localStorage.setItem(REFRESH_TOKEN_NAME, data[REFRESH_TOKEN_NAME])
        }
        if(data.rememberMe) {
            localStorage.setItem(REMEMBER_ME, data[REMEMBER_ME])
        }
    }

    async getAccessToken() {
        const token = localStorage.getItem(ACCESS_TOKEN_NAME)
        if(token) {
            if (this.isExpired(token)) {
                if(this.shouldRememberMe() && this.canRefreshToken()) {
                    await this.refreshToken()
                    return this.getAccessToken()
                } else {
                    this.logout()
                    return null
                }
            } else if(this.isCloseToExpire(token) && this.canRefreshToken()) {
                await this.refreshToken()
                return this.getAccessToken()
            }

            return token
        }

        return token ?? null
    }

    isExpired(token) {
        return this.getExpiration(token) < 0
    }

    isCloseToExpire(token) {
        const expiration = this.getExpiration(token)
        return !this.isExpired(token) && expiration < 5*60
    }

    getExpiration(token) {
        const decoded = jwtDecode(token)
        const ts = ((new Date()).getTime())
        const expiration = decoded.exp - ts/1000
        return expiration

    }

    logout() {
        localStorage.clear();
        sessionStorage.clear()
    }

    shouldRememberMe() {
        const shouldRememberMe = localStorage.getItem(REMEMBER_ME)
        return shouldRememberMe === 'true' || shouldRememberMe === true
    }

    canRefreshToken() {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME)
        if(!refreshToken) {
            return false
        }
        return !this.isExpired(refreshToken)
    }

    async refreshToken() {
        if(this.canRefreshToken()) {
            try {
                const newData = await Api.post('/auth/refreshToken', {}, {token: localStorage.getItem(REFRESH_TOKEN_NAME)})
                this.setAuthData(newData)
            } catch (e) {
                await this.logout()
            }


        }
    }
}

function getSession() {
    if(!session) {
        session = new Session()
    }
    return session
}

export default getSession