import {Component} from "react";
import getSession from './Session'
import Api from "./Lib/Api";
import Loading from "./Loading";
import {Navigate} from "react-router-dom";
import withRouter from "./Component/withRouter";

class ResetPassword extends Component {
    constructor() {
        super();
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    async resetPassword(data) {
        const {token} = this.props.params
        return Api.post('/user/resetPassword', {
            newPassword: data.password,
            token,
        }, {token: null})
    }

    state = {
        isLoading: true,
        isLoggedIn: false,
        password: '',
        passwordValidationMessage: '',
        repeatPassword: '',
        repeatPasswordValidationMessage: '',
        generalErrorMessage: '',
        generalInfoMessage: '',
    }
    async componentDidMount() {
        const session = getSession()
        const accessToken = await session.getAccessToken()
        this.setState({isLoggedIn: !!accessToken, isLoading: false})
    }

    render() {
        if(this.state.isLoading) {
            return <Loading />
        } else {
            return this.state.isLoggedIn ? <Navigate to="/dashboard" replace={true} />
                : (<div className="hold-transition login-page">
                    <div className="login-box">
                        <div className="card card-outline card-primary">
                            <div className="card-body">
                                <p className="login-box-msg">Reset Password</p>
                                <span id={'generalErrorMessage'} className="text-danger">{this.state.generalErrorMessage}</span>
                                <span id={'generalInfoMessage'} className="text-success">{this.state.generalInfoMessage}</span>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="input-group mb-3">
                                        <input name={'password'} type="password" aria-describedby={'passwordValidationMessage'} value={this.state.password} className={this.formClasses('passwordValidationMessage')} placeholder="Password" onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                        {this.validationMessage('passwordValidationMessage')}
                                    </div>
                                    <div className="input-group mb-3">
                                        <input name={'repeatPassword'} type="password" aria-describedby={'repeatPasswordValidationMessage'} value={this.state.repeatPassword} className={this.formClasses('repeatPasswordValidationMessage')} placeholder="Retype password" onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                        {this.validationMessage('repeatPasswordValidationMessage')}
                                    </div>
                                    <div className={'input-group mb-3'}>
                                            <button type="submit" className="btn btn-primary btn-block">Reset</button>
                                    </div>
                                </form>

                                <p className="mb-1">
                                    <a href="/login">I remember the password, go to login page</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }
    handleInputChange(e) {
        const target = e.target;
        let value = target.type === 'checkbox' ? !!target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async e => {
        e.preventDefault()
        const data = {...this.state}
        let isValid = true;
        const newState = {
            passwordValidationMessage: '',
            repeatPasswordValidationMessage: '',
            generalErrorMessage: '',
            generalInfoMessage: '',
        };

        if(this.state.password.length < 8) {
            isValid = false
            newState.passwordValidationMessage = 'new password should be longer or equal 8 characters'
        }

        if(this.state.password !== this.state.repeatPassword) {
            isValid = false
            newState.repeatPasswordValidationMessage = 'password and retype password should be the same'
        }

        if(isValid) {
            const result = await this.resetPassword(data);
            if(result.statusCode === 400) {
                newState.generalErrorMessage = result.message
            } else if(result.success) {
                newState.generalInfoMessage = `Password has been changed.`
                newState.password = ''
                newState.repeatPassword = ''
            } else {
                newState.generalErrorMessage = `Outdated token. Please try one more time`
                newState.password = ''
                newState.repeatPassword = ''
            }
        }
        this.setState(newState);
    }

    formClasses(field) {
        return ['form-control', this.state[field] ? 'is-invalid' : undefined].join(' ')
    }
    validationMessage(field) {
        return (<span id={field} className="error invalid-feedback is-invalid">{this.state[field]}</span>)
    }
}

export default withRouter(ResetPassword)