import {Component} from "react";
import ChangePassword from "./Components/ChangePassword";
import PlayerDetails from "./Components/PlayerDetails";
import Devices from "./Components/Devices";

class Profile extends Component {
    render() {
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Profile</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">Home</li>
                                <li className="breadcrumb-item active">Profile</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <PlayerDetails />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ChangePassword />
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*        <Token />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-lg-12">
                            <Devices />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Profile