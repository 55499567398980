import {Fragment, Component} from "react";
import Sidebar from "./Sidebar/Sidebar";
import Nav from "./Sidebar/Nav";
import getSession from "../Common/Session";
import {Navigate} from "react-router-dom";
import Loading from "../Common/Loading";
import Footer from "../Common/Footer";
import {refreshConfiguration} from "../Common/Storage";

class Main extends Component {
    state = {
        isLoading: true,
        isLoggedIn: false
    }
    async componentDidMount() {
        const session = getSession()
        const accessToken = await session.getAccessToken()
        if(accessToken) {
            await refreshConfiguration()
        }
        this.setState({isLoggedIn: !!accessToken, isLoading: false})
    }

    render() {
        if(this.state.isLoading) {
            return <Loading />
        } else {
            return !this.state.isLoggedIn ? <Navigate to="/login" replace={true} />
                : (
                <Fragment>
                    <Nav />
                    <Sidebar />
                    {this.props.content}
                    <Footer/>
                </Fragment>
            )
        }
    }
}

export default Main