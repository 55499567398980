import {Component} from "react";
import getSession from "../../Common/Session";

class Nav extends Component {
    render() {
        const logout = async () => {
            getSession().logout()
            window.location.reload(false);
        }

        return (<nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                        className="fas fa-bars"></i></a>
                </li>
            </ul>
            <ul className={"navbar-nav ml-auto"}>
                <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" onClick={logout} role="button">
                        Logout
                    </a>
                </li>
            </ul>
        </nav>)
    }
}

export default Nav